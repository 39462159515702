import React, { useState, useEffect, useMemo } from "react";
import { NavLink, useHistory } from "react-router-dom";
// import { toast } from "react-toastify";
import { TableHeader, Pagination, Search } from "../Table";
import { Dropdown, Table } from "react-bootstrap";
import Loader from "../include/Loader";
import Menu from "../include/Menu";
import Spinner from "../include/Spinner";
// import Footer from "./include/Footer";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";

const Restaurants = () => {
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const [limit, setlimit] = useState(10);
  const [loader, setLoader] = useState(false);
  const [id, setId] = useState(null);
  const [approve, setApprove] = useState(false);
  const [reject, setReject] = useState(false);
  const [text, setText] = useState(null);
  const Header = [
    {
      name: "Sr. NO.",
      field: "sr_no",
      sortable: false,
    },
    // {
    //   name: "Image",
    //   field: "image",
    //   sortable: false,
    // },
    {
      name: "Restaurant Name",
      field: "restaurant_name",
      sortable: false,
    },
    {
      name: "Manager Name",
      field: "manager_name",
      sortable: false,
    },
    {
      name: "Email",
      field: "email",
      sortable: false,
    },
    {
      name: "Phone Number",
      field: "phone_number",
      sortable: false,
    },
    {
      name: "Address",
      field: "address",
      sortable: false,
    },
    {
      name: "Status",
      field: "status",
      sortable: false,
    },
    {
      name: "Action",
      field: "action",
      sortable: false,
    },
    {
      name: "Menu",
      field: "Menu",
      sortable: false,
    },
  ];
  let history = useHistory();

  const [restoList, setRestoList] = useState(
    localStorage.getItem("restaurants")
      ? JSON.parse(localStorage.getItem("restaurants"))
      : []
  );
  function tConvert(time) {
    // Check correct time format and split into components
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(""); // return adjusted time or original string
  }
  const getResto = () => {
    const myurl = `${process.env.REACT_APP_URL}api/admin/restaurants-list`;
    var bodyFormData = new URLSearchParams();
    bodyFormData.append("auth_code", "Brud#Cust$&$Resto#MD");

    axios({
      method: "post",
      url: myurl,
      data: bodyFormData,
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    })
      .then((response) => {
        // console.log(response["data"]["data"]);
        // console.log(response);
        var indexedData = response["data"]["data"]?.map((e, i) => {
          e = { ...e };
          e = { ...e, sr_no: i + 1 };
          e = {
            ...e,
            createdAt: setDateFormat(e.createdAt),
            email: e.email ? e.email : "N/A",
            restaurant_name: e.restaurant_name ? e.restaurant_name : "N/A",
            manager_name: e.manager_name ? e.manager_name : "N/A",

            phone: e.phone_number && e.country_code ? e.country_code + e.phone_number : "N/A",
            full_address: e.full_address ? e.full_address : "N/A",
            createdat: e.createdat ? e.createdat : "N/A",
          };
          return e;
        });
        setLoader(true);
        setRestoList(indexedData);
        localStorage.setItem("restaurants", JSON.stringify(indexedData));
      })
      .catch((error) => {
        setLoader(true);
        console.log("Errors", error);
      });
  };

  useEffect(() => {
    let currantDate = new Date();

    var temp = localStorage.getItem("set");
    var date = new Date();
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    getResto();
    // if (temp) {
    //   if (temp == "1") {
    //     if (localStorage.getItem("1") == null) {
    //       localStorage.setItem("1", temp);
    //       getResto();
    //     } else {
    //       setLoader(true);
    //     }
    //   } else if (temp == "2") {
    //     setLoader(true);
    //   } else if (temp == "3") {
    //     if (
    //       new Date(localStorage.getItem("3")).getMonth() !=
    //       new Date().getMonth()
    //     ) {
    //       getResto();
    //     } else {
    //       if (
    //         new Date(localStorage.getItem("3")).getFullYear() !=
    //         new Date().getFullYear()
    //       ) {
    //         getResto();
    //       } else {
    //         setLoader(true);
    //       }
    //     }
    //   } else if (temp == "4") {
    //     if (
    //       new Date(localStorage.getItem("4")).toDateString() !=
    //       new Date().toDateString()
    //     ) {
    //       getResto();
    //     } else {
    //       setLoader(true);
    //     }
    //   }
    // } else {
    //   getResto();
    // }

    document.getElementById("page-loader").style.display = "none";

    var element = document.getElementById("page-container");
    element.classList.add("show");
  }, []);

  function setDateFormat(e) {
    var d = new Date(e);
    return (
      ("0" + d.getDate()).slice(-2) +
      "-" +
      ("0" + (d.getMonth() + 1)).slice(-2) +
      "-" +
      d.getFullYear() +
      " " +
      tConvert(
        ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2)
      )
    );
  }
  const commentsData = useMemo(() => {
    let computedComments = restoList;

    if (search) {
      computedComments = computedComments.filter(
        (restoList) =>
          restoList.restaurant_name
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          (restoList.manager_name && restoList.manager_name.toLowerCase().includes(search.toLowerCase())) ||
          (restoList.email && restoList.email.toLowerCase().includes(search.toLowerCase())) ||
          (restoList.phone_number && restoList.phone_number.includes(search)) ||
          (restoList.full_address && restoList.full_address.toLowerCase().includes(search.toLowerCase()))
      );
    }
    setTotalItems(computedComments.length);

    //Sorting comments
    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * limit,
      (currentPage - 1) * limit + limit
    );
  }, [currentPage, search, sorting, restoList, limit]);

  const deleteUser = (id) => {
    const myurl = `${process.env.REACT_APP_URL}api/admin/delete-restaurant`;
    var bodyFormData = new URLSearchParams();
    bodyFormData.append("auth_code", "Brud#Cust$&$Resto#MD");
    bodyFormData.append("id", id);

    axios({
      method: "post",
      url: myurl,
      data: bodyFormData,
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    })
      .then((response) => {
        getResto();
      })
      .catch((error) => {
        console.log("Errors", error);
      });
  };

  const handleRest = (id, num) => {
    setId(id);
    if (num === 1) {
      setApprove(true);
    } else {
      setReject(true);
    }
  }

  const handleCancel = () => {
    setText(null);
    setId(null);
    if (reject) {
      setReject(false);
    } else {
      setApprove(false);
    }
  }
  const handleRestResponse = () => {
    const myurl = `${process.env.REACT_APP_URL}api/admin/approve-reject-restaurant`;
    var bodyFormData = new URLSearchParams();
    bodyFormData.append("auth_code", "Brud#Cust$&$Resto#MD");
    bodyFormData.append("id", id);
    bodyFormData.append("type", approve ? 0 : 1);
    bodyFormData.append("text", text ?? "")
    axios({
      method: "post",
      url: myurl,
      data: bodyFormData,
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    })
      .then((response) => {
        handleCancel();
        getResto();
      })
      .catch((error) => {
        console.log("Errors", error);
      });
  }
  return (
    <>
      {reject &&
        <SweetAlert
          warning
          showCancel
          confirmBtnText="Yes, reject it!"
          confirmBtnBsStyle="danger"
          title="Are you sure want to reject it?"
          onConfirm={handleRestResponse}
          onCancel={handleCancel}
          focusCancelBtn
        >
          <textarea style={{
            width: "100%",
            borderRadius: "10px",
            borderColor: "#cdcdcd",
            height: "50px",
            padding: "10px",
          }} value={text} onChange={(e) => setText(e.target.value)} placeholder="Please enter your reason" />
        </SweetAlert>}
      {approve &&
        <SweetAlert
          success
          showCancel
          confirmBtnText="Yes, approve it!"
          title="Are you sure want to approve it?"
          onConfirm={handleRestResponse}
          onCancel={handleCancel}
        >
        </SweetAlert>}
      <Loader />

      <div
        id="page-container"
        className="fade page-sidebar-fixed page-header-fixed"
      >
        <Menu />

        <div id="content" className="content">
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item">
              <NavLink to="/dashboard">
                <span className="basePath">Dashboard</span>
              </NavLink>
            </li>
            <li className="breadcrumb-item active currentPath">Restaurants</li>
          </ol>
          <h1 className="page-header">Restaurants</h1>
          {loader ? (
            <>
              <div
                style={{
                  backgroundColor: "white",
                  padding: "20px",
                  borderRadius: "20px",
                }}
              >
                <div className="row w-100">
                  <div className="mb-3 col-12 text-center">
                    <div className="row">
                      <div className="col-xl-6 col-lg-6 col-sm-6 col-12 mb-3">
                        <div className="ml-0">
                          <div className="d-flex">
                            <h5 className="mt-2 mr-1">Search: </h5>
                            <Search
                              onSearch={(value) => {
                                setSearch(value);
                                setCurrentPage(1);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-sm-6 col-12 d-flex justify-content-end mb-3">
                        <div
                          style={{
                            color: "black",
                            fontSize: "12px",
                            fontWeight: "300",
                            paddingTop: "0px",
                            paddingBottom: "0px",
                          }}
                          className="align-self-center"
                        >
                          <b>Rows per page :&nbsp;</b>
                        </div>
                        <div className="align-self-center">
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="none"
                              id="dropdown-basic"
                              style={{
                                cursor: "auto",
                                backgroundColor: "white",
                                borderColor: "#d5dbe0",
                                paddingBottom: "3px",
                                paddingTop: "3px",
                              }}
                            >
                              {limit}&nbsp;<i className="fa fa-caret-down"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {limit !== 10 ? (
                                <>
                                  <Dropdown.Item
                                    onClick={() => {
                                      setlimit(10);
                                    }}
                                  >
                                    10
                                  </Dropdown.Item>
                                </>
                              ) : null}

                              {limit !== 20 ? (
                                <>
                                  <Dropdown.Item
                                    onClick={() => {
                                      setlimit(20);
                                    }}
                                  >
                                    20
                                  </Dropdown.Item>
                                </>
                              ) : null}

                              {limit !== 30 ? (
                                <>
                                  <Dropdown.Item
                                    onClick={() => {
                                      setlimit(30);
                                    }}
                                  >
                                    30
                                  </Dropdown.Item>
                                </>
                              ) : null}

                              {limit !== 50 ? (
                                <>
                                  <Dropdown.Item
                                    onClick={() => {
                                      setlimit(50);
                                    }}
                                  >
                                    50
                                  </Dropdown.Item>
                                </>
                              ) : null}
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    </div>

                    <div className="row ">
                      <div className="col-12">
                        <div className="table-responsive">
                          <Table striped bordered hover>
                            <thead>
                              <TableHeader
                                headers={Header}
                                onSorting={(field, order) =>
                                  setSorting({ field, order })
                                }
                              />
                            </thead>
                            <tbody>
                              {commentsData.map((e, i) => (
                                <tr>
                                  <td>{e.sr_no}</td>
                                  {/* <td>
                                    {e?.image?.match("undefined") !=
                                      "undefined" ? (
                                      e?.image != null ? (
                                        <img
                                          src={e.image}
                                          width="70px"
                                          height="60px"
                                          alt="restoImg"
                                        />
                                      ) : (
                                        <img
                                          src="./assets/img/icon/restaurantIcon.png"
                                          width="50px"
                                          height="50px"
                                          alt="customer"
                                        />
                                      )
                                    ) : (
                                      <img
                                        src="./assets/img/icon/restaurantIcon.png"
                                        width="50px"
                                        height="50px"
                                        alt="customer"
                                      />
                                    )}
                                  </td> */}
                                  <td>{e.restaurant_name}</td>
                                  <td>{e.manager_name}</td>
                                  <td>{e.email}</td>
                                  <td>
                                    {e.phone}
                                  </td>
                                  <td>
                                    {e.full_address}
                                  </td>
                                  <td>
                                    {e?.stripe_account_completed ?
                                      e?.status === 0 ? (
                                        <i
                                          class="fa fa-user-slash"
                                          style={{ color: "red", fontSize: "18px" }}
                                        ></i>
                                      ) : e?.status === 1 ? (
                                        <div style={{ display: "flex", gap: "10px" }}>
                                          <i
                                            class="fa fa-check-square"
                                            style={{
                                              color: "green",
                                              fontSize: "23px",
                                              cursor: "pointer"
                                            }}
                                            onClick={() => handleRest(e?.id, 1)}
                                          ></i>
                                          <i
                                            class="fa fa-window-close"
                                            style={{
                                              color: "red",
                                              fontSize: "23px",
                                              cursor: "pointer"
                                            }}
                                            onClick={() => handleRest(e?.id, 2)}
                                          ></i>
                                        </div>
                                      ) : (<i
                                        class="fa fa-universal-access"
                                        style={{
                                          color: "green",
                                          fontSize: "23px",
                                        }}
                                      ></i>) :
                                      e?.status === 0 ? (
                                        <i
                                          class="fa fa-user-slash"
                                          style={{ color: "red", fontSize: "18px" }}
                                        ></i>
                                      ) :
                                        <i
                                          class="fa fa-exclamation-circle"
                                          style={{
                                            color: "#FDDA0D",
                                            fontSize: "23px",
                                            cursor: "pointer"
                                          }}
                                          title="Stripe setup is pending"
                                        ></i>}
                                  </td>
                                  {/* <td>{e.createdAt}</td> */}
                                  <td >
                                    <span style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "13px", padding: "0 5px" }}>
                                      <i className="fa fa-eye edit"
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          history.push({
                                            pathname: "/restaurantDetails",
                                            state: e,
                                          })
                                        }
                                      ></i>
                                      {e.status === 2 &&
                                        <i className="fa fa-pen edit"
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            history.push({
                                              pathname: "/updateRestaurantDetails",
                                              state: e,
                                            })
                                          }
                                        ></i>}
                                      <i
                                        className={
                                          `fa fa-trash ` + `${e?.status}?delete:''`
                                        }
                                        style={{
                                          cursor: "pointer",
                                          opacity: e?.status ? "1" : "0.5",
                                        }}
                                        onClick={() =>
                                          e?.status
                                            ? window.confirm(
                                              "Do you want to delete ?"
                                            )
                                              ? deleteUser(e.id)
                                              : null
                                            : null
                                        }
                                      ></i>
                                    </span>
                                  </td>
                                  <td>
                                    <i
                                      className="fa fa-bars edit"
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        history.push({
                                          pathname: "/restaurantItems",
                                          state: e.id,
                                        })
                                      }
                                    ></i>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                          {commentsData.length == 0 ? (
                            <div className="row d-flex justify-content-center w-100">
                              <div className="mx-auto  d-flex justify-content-center w-100">
                                <img
                                  src="./assets/img/icon/no-location.png"
                                  className="form-img__img-preview"
                                  style={{ width: "100px", height: "100px" }}
                                  alt=""
                                />
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div
                      className="mt-2 d-flex justify-content-sm-center justify-content-xs-center justify-content-lg-end"
                      style={{
                        overflowX: "auto",
                      }}
                    >
                      <Pagination
                        total={totalItems}
                        itemsPerPage={limit}
                        currentPage={currentPage}
                        onPageChange={(page) => setCurrentPage(page)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <Spinner />
          )}
        </div>
      </div>
    </>
  );
};

export default Restaurants;
