import React, { useState, useEffect, useMemo } from "react";
import { NavLink } from "react-router-dom";
import Loader from "../include/Loader";
import Menu from "../include/Menu";
import Footer from "../include/Footer";
import axios from "axios";
import { TableHeader, Pagination, Search } from "../Table";
import { Dropdown, Table } from "react-bootstrap";
import Spinner from "../include/Spinner";
import { useHistory } from "react-router-dom";

export default function Report() {
  let history = useHistory();

  const m = new Date()?.getMonth() + 1;
  const [error, setError] = useState({});
  const [report, setReport] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const [limit, setlimit] = useState(10);
  const [loader, setLoader] = useState(false);
  const [month, setMonth] = useState(m);
  const [mon, setMon] = useState(m);
  const [year, setYear] = useState(new Date()?.getFullYear());
  const [yea, setYea] = useState(new Date()?.getFullYear());
  const [disable, setDisable] = useState(false);
  //(m < 10 ? '0' : '') + m
  const Header = [
    {
      name: "Sr. NO.",
      field: "sr_no",
      sortable: false,
    },

    {
      name: "Restaurant Name",
      field: "restaurant_name",
      sortable: false,
    },
    {
      name: "Email",
      field: "email",
      sortable: false,
    },
    {
      name: "Phone Number",
      field: "phone_number",
      sortable: false,
    },
    {
      name: "Monthly Payout",
      field: "monthly_payout",
      sortable: false,
    },
    {
      name: "Status",
      field: "status",
      sortable: false,
    },
  ];
  const monthName = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  useEffect(() => {
    getReport();
    document.getElementById("page-loader").style.display = "none";

    var element = document.getElementById("page-container");
    element.classList.add("show");
  }, []);

  const getReport = () => {
    setLoader(false);

    const myurl = `${process.env.REACT_APP_URL}api/admin/restaurants-monthly-report`;
    var bodyFormData = new URLSearchParams();
    bodyFormData.append("auth_code", "Brud#Cust$&$Resto#MD");
    bodyFormData.append("month", (month < 10 ? "0" : "") + month);
    bodyFormData.append("year", year);

    axios({
      method: "post",
      url: myurl,
      data: bodyFormData,
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    })
      .then((response) => {
        console.log(response["data"]["data"]);
        setLoader(true);
        if (response?.data?.sucecess) {
          var indexedData = response["data"]["data"]?.map((e, i) => {
            e = { ...e };
            e = { ...e, sr_no: i + 1 };
            e = { ...e, status: e?.status == "1" ? "Pending" : "Paid" };

            return e;
          });
          setMon(month);
          setYea(year);
          setReport(indexedData);
        }
      })
      .catch((error) => {
        setLoader(true);
        console.log("Errors", error);
      });
  };
  const validate = () => {
    let isValid = true;
    let error = {};
    if (month == "-1") {
      isValid = false;
      error["month"] = "Please select month";
    }
    console.log(year?.length);
    if (year?.toString()?.length != "4") {
      isValid = false;
      error["year"] = "Please enter valid year";
    }
    setError(error);
    return isValid;
  };

  const formSubmit = (e) => {
    e.preventDefault();
    setDisable(true);
    if (validate()) {
      getReport();
      setDisable(false);
    }
    setDisable(false);
  };
  const commentsData = useMemo(() => {
    let computedComments = report;

    if (search) {
      computedComments = computedComments.filter(
        (report) =>
          report.restaurant_name.toLowerCase().includes(search.toLowerCase()) ||
          report.email.toLowerCase().includes(search.toLowerCase()) ||
          report.phone_number.toLowerCase().includes(search.toLowerCase())
      );
    }
    setTotalItems(computedComments.length);

    //Sorting comments
    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * limit,
      (currentPage - 1) * limit + limit
    );
  }, [currentPage, search, sorting, report, limit]);

  return (
    <>
      <Loader />

      <div
        id="page-container"
        className="fade page-sidebar-fixed page-header-fixed"
      >
        <Menu />

        <div id="content" className="content">
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item">
              <NavLink to="/dashboard">
                <span className="basePath">Dashboard</span>
              </NavLink>
            </li>
            <li className="breadcrumb-item active currentPath">Report</li>
          </ol>
          <h1 className="page-header">Report</h1>

          {loader ? (
            <>
              <button
                type="button"
                className="btn btn-secondary mb-3"
                data-toggle="collapse"
                data-target="#collapseExample"
                aria-expanded="false"
                aria-controls="collapseExample"
                style={{ borderRadius: "20px" }}
              >
                GET REPORT
              </button>
              <div
                className="collapse"
                id="collapseExample"
                style={{ borderRadius: "20px" }}
              >
                <div
                  className="card card-body"
                  style={{ borderRadius: "20px" }}
                >
                  <div className="filterDiv">
                    <form onSubmit={formSubmit}>
                      <div className="form-group">
                        <label
                          className=""
                          for="inputState"
                          style={{ fontSize: "16px", fontWeight: "600" }}
                        >
                          Month:
                        </label>
                        <br />
                        <select
                          className="form-control ml-0"
                          aria-label="Default select example"
                          style={{ borderRadius: "20px" }}
                          value={month}
                          onChange={(e) => {
                            if (e.target.value != 0) {
                              setMonth(e.target.value);
                            } else {
                              setMonth(-1);
                            }
                          }}
                        >
                          {monthName.map((e, i) => {
                            return <option value={i + 1}>{e}</option>;
                          })}
                        </select>
                        <div className="text-danger">{error.month}</div>
                      </div>

                      <div className="form-group ">
                        <label
                          for="exampleInputPassword1"
                          className="mb-0"
                          style={{ fontSize: "16px", fontWeight: "600" }}
                        >
                          Year
                        </label>
                        <input
                          type="number"
                          className="form-control ml-0"
                          placeholder="YYYY"
                          style={{ borderRadius: "20px" }}
                          name="date"
                          value={year}
                          onChange={(e) => setYear(e.target.value)}
                        />
                        <div className="text-danger">{error.year}</div>
                      </div>
                      <button
                        className="btn btn-success save-btp"
                        type="submit"
                        name="upload"
                        style={{
                          borderRadius: "20px",
                        }}
                        disabled={disable}
                      >
                        {disable ? "Processing..." : "Submit"}
                      </button>
                    </form>
                  </div>
                </div>
              </div>
              <br />
              <div
                style={{
                  backgroundColor: "white",
                  padding: "20px",
                  borderRadius: "20px",
                }}
              >
                <div className="row w-100">
                  <div className="mb-3 col-12 text-center">
                    <div className="row">
                      <div className="col-xl-6 col-lg-6 col-sm-6 col-12 mb-3">
                        <div className="ml-0">
                          <div className="d-flex">
                            <h5 className="mt-2 mr-1">Search: </h5>
                            <Search
                              onSearch={(value) => {
                                setSearch(value);
                                setCurrentPage(1);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-sm-6 col-12 d-flex justify-content-end mb-3">
                        <div
                          style={{
                            color: "black",
                            fontSize: "12px",
                            fontWeight: "300",
                            paddingTop: "0px",
                            paddingBottom: "0px",
                          }}
                          className="align-self-center"
                        >
                          <b>Rows per page :&nbsp;</b>
                        </div>
                        <div className="align-self-center">
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="none"
                              id="dropdown-basic"
                              style={{
                                cursor: "auto",
                                backgroundColor: "white",
                                borderColor: "#d5dbe0",
                                paddingBottom: "3px",
                                paddingTop: "3px",
                              }}
                            >
                              {limit}&nbsp;<i class="fa fa-caret-down"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {limit !== 10 ? (
                                <>
                                  <Dropdown.Item
                                    onClick={() => {
                                      setlimit(10);
                                    }}
                                  >
                                    10
                                  </Dropdown.Item>
                                </>
                              ) : null}

                              {limit !== 20 ? (
                                <>
                                  <Dropdown.Item
                                    onClick={() => {
                                      setlimit(20);
                                    }}
                                  >
                                    20
                                  </Dropdown.Item>
                                </>
                              ) : null}

                              {limit !== 30 ? (
                                <>
                                  <Dropdown.Item
                                    onClick={() => {
                                      setlimit(30);
                                    }}
                                  >
                                    30
                                  </Dropdown.Item>
                                </>
                              ) : null}

                              {limit !== 50 ? (
                                <>
                                  <Dropdown.Item
                                    onClick={() => {
                                      setlimit(50);
                                    }}
                                  >
                                    50
                                  </Dropdown.Item>
                                </>
                              ) : null}
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    </div>
                    <div className=" ml-0 mb-2">
                      <h4>
                        Report :{" "}
                        <b>
                          {monthName[mon - 1]}, {yea}
                        </b>
                      </h4>
                    </div>

                    <div className="row ">
                      <div className="col-12">
                        <div className="table-responsive">
                          <Table striped bordered hover>
                            <thead>
                              <TableHeader
                                headers={Header}
                                onSorting={(field, order) =>
                                  setSorting({ field, order })
                                }
                              />
                            </thead>
                            <tbody>
                              {commentsData.map((e, i) => (
                                <tr>
                                  <td>{e.sr_no}</td>
                                  <td>{e?.restaurant_name}</td>
                                  <td>{e?.email}</td>
                                  <td>
                                    {e?.country_code + " " + e?.phone_number}
                                  </td>
                                  <td>{e?.monthly_payout}</td>
                                  <td>
                                    {e?.status == "Pending" ? (
                                      <span
                                        style={{
                                          cursor: "pointer",
                                          fontWeight: "500",
                                          color: "red",
                                        }}
                                        onClick={() =>
                                          history.push({
                                            pathname: "/report-state-change",
                                            state: { e, month, year },
                                          })
                                        }
                                      >
                                        {e?.status}
                                      </span>
                                    ) : (
                                      <span
                                        style={{
                                          cursor: "pointer",
                                          color: "green",
                                          fontWeight: "600",
                                        }}
                                      >
                                        {e?.status}
                                      </span>
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                          {commentsData.length == 0 ? (
                            <div className="row d-flex justify-content-center w-100">
                              <div className="mx-auto  d-flex justify-content-center w-100">
                                <img
                                  src="./assets/img/icon/no-location.png"
                                  className="form-img__img-preview"
                                  style={{ width: "100px", height: "100px" }}
                                  alt=""
                                />
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div
                      className="mt-2 d-flex justify-content-sm-center justify-content-xs-center justify-content-lg-end"
                      style={{
                        overflowX: "auto",
                      }}
                    >
                      <Pagination
                        total={totalItems}
                        itemsPerPage={limit}
                        currentPage={currentPage}
                        onPageChange={(page) => setCurrentPage(page)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <Spinner />
          )}
        </div>

        {/* <Footer /> */}
      </div>
    </>
  );
}
