import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { toast } from "react-toastify";
import axios from "axios";

function ForgotPassword() {
  const history = useHistory();
  const [loginInfo, setLoginInfo] = useState({
    email: "",
  });
  const [errors, setErrors] = useState({});
  const [disable, setDisable] = useState(false);

  const InputEvent = (e) => {
    const newLoginInfo = { ...loginInfo };
    newLoginInfo[e.target.name] = e.target.value;
    setLoginInfo(newLoginInfo);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (validate()) {
      setDisable(true);
      const { email, } = loginInfo;

      const myurl = `${process.env.REACT_APP_URL}api/admin/forgot-password`;
      var bodyFormData = new URLSearchParams();
      bodyFormData.append("email", email);
      axios({
        method: "post",
        url: myurl,
        data: bodyFormData,
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      })
        .then((response) => {
          //console.log(response);
          if (response.data.sucecess == true) {
            toast.success("Link sent to reset your password !");
            history.push(`/brud-admin`);
          } else {
            setDisable(false);
            toast.error("Email doesn't exists");
          }
        })
        .catch((error) => {
          console.log("Errors", error);
          setDisable(false);
          toast.error("Email doesn't exists");
        });
    } else {
      setDisable(false);
    }
  };

  const validate = () => {
    let input = loginInfo;

    let errors = {};
    let isValid = true;

    if (!input["email"]) {
      isValid = false;
      errors["email_err"] = "Please enter email";
    }

    setErrors(errors);
    return isValid;
  };

  useEffect(() => {
    document.getElementById("page-loader").style.display = "none";

    var element = document.getElementById("page-container");
    element.classList.add("show");
  }, []);

  return (
    <>
      <div id="page-loader" className="fade show">
        <span className="spinner"></span>
      </div>

      <div className="login-cover">
        <div
          className="login-cover-image"
          style={{
            backgroundImage: "url(assets/img/login-bg/login-bg-17.jpg)",
          }}
          data-id="login-cover-image"
        ></div>
        <div className="login-cover-bg"></div>
      </div>

      <div id="page-container" className="fade">
        <div
          className="login login-v2"
          data-pageload-addclassName="animated fadeIn"
        >
          <div className="login-header">
            <div className="brand">
              <span className="logo"></span> <b>BRUD</b> Admin
              {/* <small>Login for BRUD admin panel</small> */}
            </div>
            <div className="icon">
              <i className="fa fa-lock"></i>
            </div>
          </div>

          <div className="login-content">
            <form onSubmit={(e) => submitHandler(e)}>
              <div className="form-group m-b-20">
                <input
                  type="text"
                  className="form-control form-control-lg ml-0"
                  placeholder="Email Address"
                  name="email"
                  onChange={InputEvent}
                />
                <div className="text-danger">{errors.email_err}</div>
              </div>

              <div className="login-buttons">
                <button
                  type="submit"
                  class="btn btn-success btn-block btn-lg"
                  disabled={disable}
                >
                  {disable ? "Processing..." : "Forgot Password"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default ForgotPassword;
