import { Switch, Route, Redirect } from "react-router-dom";
import Login from "./components/admin/Authentication/Login";
import Dashboard from "./components/admin/Dashboard/Dashboard";
import Profile from "./components/admin/Profile/Profile";
import Restaurants from "./components/admin/Restaurant/Restaurants";
import Banners from "./components/admin/Banner/Banners";
import Category from "./components/admin/Category/Category";
import Customers from "./components/admin/Customers/Customers";
import RestoDetails from "./components/admin/Restaurant/RestoDetails";
import Orders from "./components/admin/Order/Orders";
import RestoItems from "./components/admin/Restaurant/RestoItem";
import OrdersDetails from "./components/admin/Order/OrdersDetails";
import RestoModifier from "./components/admin/Restaurant/RestoModifier";
import UpdateRestoDetail from "./components/admin/Restaurant/updateRestoDetail";
import Deals from "./components/admin/Deals/Deals";
import DealsDetails from "./components/admin/Deals/dealsDetails";
import UpdateDeals from "./components/admin/Deals/UpdateDeals";
import AddDeals from "./components/admin/Deals/AddDeals";
import Report from "./components/admin/report/Report";
import ChangeState from "./components/admin/report/ChangeState";
import ForgotPassword from "./components/admin/Authentication/ForgotPassword";
import ResetPassword from "./components/admin/Authentication/ResetPassword";
import Payout from "./components/admin/Payout/Payout";
import Tax from "./components/admin/Tax/Tax";

function App() {
  return (
    <>
      <Switch>
        <Route path="/brud-admin" exact component={Login} />
        <Route path="/forgot-password" exact component={ForgotPassword} />
        <Route path="/reset-password/:id" exact component={ResetPassword} />
        <Route path="/admin-profile" exact component={Profile} />
        <Route path="/dashboard" exact component={Dashboard} />
        <Route path="/restaurants" exact component={Restaurants} />
        <Route path="/restaurantDetails" exact component={RestoDetails} />

        <Route path="/orders" exact component={Orders} />
        <Route path="/ordersDetails" exact component={OrdersDetails} />

        <Route path="/banners" exact component={Banners} />
        <Route path="/restaurantItems" exact component={RestoItems} />
        <Route path="/restaurantModifier" exact component={RestoModifier} />
        <Route
          path="/updateRestaurantDetails"
          exact
          component={UpdateRestoDetail}
        />

        <Route path="/category" exact component={Category} />
        <Route path="/customers" exact component={Customers} />
        <Route path="/deals" exact component={Deals} />
        <Route path="/dealsDetails" exact component={DealsDetails} />
        <Route path="/updateDeals" exact component={UpdateDeals} />
        <Route path="/addDeals" exact component={AddDeals} />

        <Route path="/payout" exact component={Payout} />

        <Route path="/tax" exact component={Tax} />

        <Route path="/report" exact component={Report} />
        <Route path="/report-state-change" exact component={ChangeState} />
        <Redirect to="/brud-admin" />
      </Switch>
    </>
  );
}

export default App;
