import React, { useState, useEffect, useMemo } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { TableHeader, Pagination, Search } from "../Table";
import { Dropdown, Table } from "react-bootstrap";
import Loader from "../include/Loader";
import Menu from "../include/Menu";
import Footer from "../include/Footer";
import axios from "axios";
import Spinner from "../include/Spinner";

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const [limit, setlimit] = useState(10);
  let history = useHistory();
  const [loader, setLoader] = useState(false);

  const Header = [
    {
      name: "Sr. NO.",
      field: "sr_no",
      sortable: false,
    },
    {
      name: "Order Code",
      field: "order_code",
      sortable: false,
    },
    {
      name: "Order Instructions",
      field: "order_instructions",
      sortable: false,
    },
    {
      name: "Total",
      field: "total",
      sortable: false,
    },
    {
      name: "Items Count",
      field: "items_count",
      sortable: false,
    },
    // {
    //   name: "Payment Type",
    //   field: "payment_type",
    //   sortable: false,
    // },
    {
      name: "Order Items",
      field: "orderItems",
      sortable: false,
    },
    {
      name: "Order Status",
      field: "status",
      sortable: false,
    },
    {
      name: "Created At",
      field: "createdAt",
      sortable: false,
    },
    {
      name: "Details",
      field: "details",
      sortable: false,
    },
  ];
  const getOrders = (size, number) => {
    setLoader(false)
    const myurl = `${process.env.REACT_APP_URL}api/admin/order-list?size=${size}&number=${number}`;
    var bodyFormData = new URLSearchParams();
    bodyFormData.append("auth_code", "Brud#Cust$&$Resto#MD");

    axios({
      method: "post",
      url: myurl,
      data: bodyFormData,
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    })
      .then((response) => {
        var indexedData = response["data"]["data"].list?.map((e, i) => {
          let k = [];
          e = { ...e };
          e = { ...e, sr_no: (i + 1 + (size * (number - 1))) };
          e = {
            ...e,
            createdAt: setDateFormat(e.createdAt),
            order_code: e.order_code ? e.order_code : "N/A",
            order_instructions: e.order_instructions
              ? e.order_instructions
              : "N/A",
            // total: e.total ? e.total : "N/A",

            // items_count: e.items_count ? e.items_count : "N/A",
            payment_type: e.payment_type ? e.payment_type : "N/A",
            createdat: e.createdat ? e.createdat : "N/A",
          };

          e.cart_items.length > 0
            ? e.cart_items.map((e) => {
              // k == "" ? (k = e.item_name) : (k = "," + e.item_name)
              k.push(e.item_name)
            })
            : (k = "N/A");
          e = { ...e, items: Array.isArray(k) ? k.join(", ") : k };
          console.log("sr_no", e.sr_no);
          return e;
        });
        setOrders(indexedData);
        setTotalItems(response["data"]["data"].total);
        setLoader(true);
      })
      .catch((error) => {
        console.log("Errors", error);
        setLoader(true);
      });
  };

  useEffect(() => {
    getOrders(limit, 1);

    document.getElementById("page-loader").style.display = "none";

    var element = document.getElementById("page-container");
    element.classList.add("show");
  }, []);

  function setDateFormat(e) {
    var d = new Date(e);
    return (
      ("0" + d.getDate()).slice(-2) +
      "-" +
      ("0" + (d.getMonth() + 1)).slice(-2) +
      "-" +
      d.getFullYear() +
      " " +
      tConvert(
        ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2)
      )
    );
  }
  function tConvert(time) {
    // Check correct time format and split into components
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(""); // return adjusted time or original string
  }
  // const commentsData = useMemo(() => {
  //   let computedComments = orders;

  //   if (search) {
  //     computedComments = computedComments.filter(
  //       (orders) =>
  //         orders.order_code.toLowerCase().includes(search.toLowerCase()) ||
  //         orders.items.toLowerCase().includes(search.toLowerCase()) ||
  //         orders.restaurants[0].state
  //           .toLowerCase()
  //           .includes(search.toLowerCase())
  //     );
  //   }
  //   setTotalItems(computedComments.length);

  //   //Sorting comments
  //   if (sorting.field) {
  //     const reversed = sorting.order === "asc" ? 1 : -1;
  //     computedComments = computedComments.sort(
  //       (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
  //     );
  //   }

  //   //Current Page slice
  //   return computedComments.slice(
  //     (currentPage - 1) * limit,
  //     (currentPage - 1) * limit + limit
  //   );
  // }, [currentPage, search, sorting, orders, limit]);
  return (
    <>
      <Loader />

      <div
        id="page-container"
        className="fade page-sidebar-fixed page-header-fixed"
      >
        <Menu />

        <div id="content" className="content">
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item">
              <NavLink to="/dashboard">
                <span className="basePath">Dashboard</span>
              </NavLink>
            </li>
            <li className="breadcrumb-item active currentPath">Orders</li>
          </ol>
          <h1 className="page-header">Orders</h1>
          {loader ? (
            <div
              style={{
                backgroundColor: "white",
                padding: "20px",
                borderRadius: "20px",
              }}
            >
              <div className="row w-100">
                <div className="mb-3 col-12 text-center">
                  <div className="row">
                    <div className="col-xl-6 col-lg-6 col-sm-6 col-12 mb-3">
                      <div className="ml-0">
                        {/* <div className="d-flex">
                          <h5 className="mt-2 mr-1">Search: </h5>
                          <Search
                            onSearch={(value) => {
                              setSearch(value);
                              getOrders(limit, 1);
                            }}
                          />
                        </div> */}
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-sm-6 col-12 d-flex justify-content-end mb-3">
                      <div
                        style={{
                          color: "black",
                          fontSize: "12px",
                          fontWeight: "300",
                          paddingTop: "0px",
                          paddingBottom: "0px",
                        }}
                        className="align-self-center"
                      >
                        <b>Rows per page :&nbsp;</b>
                      </div>
                      <div className="align-self-center">
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="none"
                            id="dropdown-basic"
                            style={{
                              cursor: "auto",
                              backgroundColor: "white",
                              borderColor: "#d5dbe0",
                              paddingBottom: "3px",
                              paddingTop: "3px",
                            }}
                          >
                            {limit}&nbsp;<i class="fa fa-caret-down"></i>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {limit !== 10 ? (
                              <>
                                <Dropdown.Item
                                  onClick={() => {
                                    setlimit(10);
                                  }}
                                >
                                  10
                                </Dropdown.Item>
                              </>
                            ) : null}

                            {limit !== 20 ? (
                              <>
                                <Dropdown.Item
                                  onClick={() => {
                                    setlimit(20);
                                  }}
                                >
                                  20
                                </Dropdown.Item>
                              </>
                            ) : null}

                            {limit !== 30 ? (
                              <>
                                <Dropdown.Item
                                  onClick={() => {
                                    setlimit(30);
                                  }}
                                >
                                  30
                                </Dropdown.Item>
                              </>
                            ) : null}

                            {limit !== 50 ? (
                              <>
                                <Dropdown.Item
                                  onClick={() => {
                                    setlimit(50);
                                  }}
                                >
                                  50
                                </Dropdown.Item>
                              </>
                            ) : null}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>

                  <div className="row ">
                    <div className="col-12">
                      <div className="table-responsive">
                        <Table striped bordered hover>
                          <thead>
                            <TableHeader
                              headers={Header}
                              onSorting={(field, order) =>
                                setSorting({ field, order })
                              }
                            />
                          </thead>
                          <tbody>
                            {orders.map((e, i) => (
                              <tr>
                                <td>{e.sr_no}</td>
                                <td>{e.order_code}</td>
                                <td>{e.order_instructions?.length > 30 ? e.order_instructions.substring(0, 30) + "..." : e.order_instructions}</td>
                                <td>$ {e.total === "N/A" ? 0 : e.total}</td>
                                <td>
                                  {e.items_count === "N/A"
                                    ? 0
                                    : e.items_count}
                                </td>
                                {/* <td>{e.payment_type}</td> */}
                                <td>{e.items}</td>
                                <td>{e.status}</td>
                                <td>{e.createdAt}</td>
                                <td>
                                  <i
                                    className="fa fa-eye edit"
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      history.push({
                                        pathname: "/ordersDetails",
                                        state: e,
                                      })
                                    }
                                  ></i>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        {orders.length == 0 ? (
                          <div className="row d-flex justify-content-center w-100">
                            <div className="mx-auto  d-flex justify-content-center w-100">
                              <img
                                src="./assets/img/icon/no-location.png"
                                className="form-img__img-preview"
                                style={{ width: "100px", height: "100px" }}
                                alt=""
                              />
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div
                    className="mt-2 d-flex justify-content-sm-center justify-content-xs-center justify-content-lg-end"
                    style={{
                      overflowX: "auto",
                    }}
                  >
                    <Pagination
                      total={totalItems}
                      itemsPerPage={limit}
                      currentPage={currentPage}
                      onPageChange={(page) => { setCurrentPage(page); getOrders(limit, page) }}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <Spinner />
          )}
        </div>

        {/* <Footer /> */}
      </div>
    </>
  );
};

export default Orders;
