import React, { useState, useEffect, useMemo } from "react";
import { NavLink, useHistory } from "react-router-dom";
// import { toast } from "react-toastify";
import { TableHeader, Pagination, Search } from "../Table";
import { Dropdown, Table } from "react-bootstrap";
import Loader from "../include/Loader";
import Menu from "../include/Menu";
import Spinner from "../include/Spinner";
// import Footer from "./include/Footer";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";
import { ToastContainer, toast } from "react-toastify";

const Payout = () => {
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });
    const [limit, setlimit] = useState(10);
    const [loader, setLoader] = useState(false);
    const [id, setId] = useState(null);
    const [approve, setApprove] = useState(false);
    const [reject, setReject] = useState(false);
    const [text, setText] = useState(null);
    const [balance, setBalance] = useState(null);
    const Header = [
        {
            name: "Sr. NO.",
            field: "sr_no",
            sortable: false,
        },
        // {
        //   name: "Image",
        //   field: "image",
        //   sortable: false,
        // },
        {
            name: "Restaurant Name",
            field: "restaurant_name",
            sortable: false,
        },
        {
            name: "Amount",
            field: "amount",
            sortable: false,
        },
        {
            name: "Status",
            field: "status",
            sortable: false,
        },
        {
            name: "Requetsed on",
            field: "createdAt",
            sortable: false,
        },
    ];
    let history = useHistory();

    const [list, setList] = useState([]);
    function tConvert(time) {
        // Check correct time format and split into components
        time = time
            .toString()
            .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

        if (time.length > 1) {
            // If time format correct
            time = time.slice(1); // Remove full string match value
            time[5] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
            time[0] = +time[0] % 12 || 12; // Adjust hours
        }
        return time.join(""); // return adjusted time or original string
    }
    const getList = () => {
        const myurl = `${process.env.REACT_APP_URL}api/admin/get-payout-request`;
        var bodyFormData = new URLSearchParams();
        bodyFormData.append("auth_code", "Brud#Cust$&$Resto#MD");

        axios({
            method: "post",
            url: myurl,
            data: bodyFormData,
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
        })
            .then((response) => {
                // console.log(response["data"]["data"]);
                // console.log(response);
                var indexedData = response["data"]["data"]?.map((e, i) => {
                    e = { ...e };
                    e = { ...e, sr_no: i + 1 };
                    e = {
                        ...e,
                        createdAt: setDateFormat(e.createdAt),
                        restaurant_name: e?.restaurant_id ? e?.restaurant_id?.restaurant_name : "N/A",
                    };
                    return e;
                });
                setLoader(true);
                setList(indexedData);
                setBalance(response["data"]["amount"])
            })
            .catch((error) => {
                setLoader(true);
                console.log("Errors", error);
            });
    };

    useEffect(() => {
        document.getElementById("page-loader").style.display = "none";

        var element = document.getElementById("page-container");
        element.classList.add("show");
        getList();
    }, []);

    function setDateFormat(e) {
        var d = new Date(e);
        return (
            ("0" + d.getDate()).slice(-2) +
            "-" +
            ("0" + (d.getMonth() + 1)).slice(-2) +
            "-" +
            d.getFullYear() +
            " " +
            tConvert(
                ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2)
            )
        );
    }
    const commentsData = useMemo(() => {
        let computedComments = list;

        if (search) {
            computedComments = computedComments.filter(
                (restoList) =>
                    restoList.restaurant_name
                        .toLowerCase()
                        .includes(search.toLowerCase()) ||
                    (restoList.manager_name && restoList.manager_name.toLowerCase().includes(search.toLowerCase())) ||
                    (restoList.email && restoList.email.toLowerCase().includes(search.toLowerCase())) ||
                    (restoList.phone_number && restoList.phone_number.includes(search)) ||
                    (restoList.full_address && restoList.full_address.toLowerCase().includes(search.toLowerCase()))
            );
        }
        setTotalItems(computedComments.length);

        //Sorting comments
        if (sorting.field) {
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedComments = computedComments.sort(
                (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
            );
        }

        //Current Page slice
        return computedComments.slice(
            (currentPage - 1) * limit,
            (currentPage - 1) * limit + limit
        );
    }, [currentPage, search, sorting, list, limit]);

    const deleteUser = (id) => {
        const myurl = `${process.env.REACT_APP_URL}api/admin/delete-restaurant`;
        var bodyFormData = new URLSearchParams();
        bodyFormData.append("auth_code", "Brud#Cust$&$Resto#MD");
        bodyFormData.append("id", id);

        axios({
            method: "post",
            url: myurl,
            data: bodyFormData,
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
        })
            .then((response) => {
                // getResto();
            })
            .catch((error) => {
                console.log("Errors", error);
            });
    };

    const handleRest = (id, num) => {
        setId(id);
        if (num === 1) {
            setApprove(true);
        } else {
            setReject(true);
        }
    }

    const handleCancel = () => {
        setText(null);
        setId(null);
        if (reject) {
            setReject(false);
        } else {
            setApprove(false);
        }
    }

    const validation = () => {
        let valid = true;
        if (reject && !text) {
            toast.error("Please enter a valid reason to reject the request")
            valid = false;
        } else {
            valid = true;
        }
        return valid;
    }
    const handleRestResponse = () => {
        if (validation()) {
            const myurl = `${process.env.REACT_APP_URL}api/admin/respond-payout-request`;
            var bodyFormData = new URLSearchParams();
            bodyFormData.append("auth_code", "Brud#Cust$&$Resto#MD");
            bodyFormData.append("payout_id", id);
            bodyFormData.append("status", approve ? 1 : 2);
            bodyFormData.append("reason", text ?? "")
            axios({
                method: "post",
                url: myurl,
                data: bodyFormData,
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
            })
                .then((response) => {
                    handleCancel();
                    getList();
                    toast.success(response.data.message)
                })
                .catch((error) => {
                    console.log("Errors", error);
                    handleCancel();
                    toast.error(error.response.data.message)
                });
        }
    }
    return (
        <>
            <ToastContainer />
            {reject &&
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Yes, reject it!"
                    confirmBtnBsStyle="danger"
                    title="Are you sure want to reject it?"
                    onConfirm={handleRestResponse}
                    onCancel={handleCancel}
                    focusCancelBtn
                >
                    <textarea style={{
                        width: "100%",
                        borderRadius: "10px",
                        borderColor: "#cdcdcd",
                        height: "50px",
                        padding: "10px",
                    }} value={text} onChange={(e) => setText(e.target.value)} placeholder="Please enter your reason" />
                </SweetAlert>}
            {approve &&
                <SweetAlert
                    success
                    showCancel
                    confirmBtnText="Yes, approve it!"
                    title="Are you sure want to approve it?"
                    onConfirm={handleRestResponse}
                    onCancel={handleCancel}
                >
                </SweetAlert>}
            <Loader />

            <div
                id="page-container"
                className="fade page-sidebar-fixed page-header-fixed"
            >
                <Menu />

                <div id="content" className="content">
                    <ol className="breadcrumb float-xl-right">
                        <li className="breadcrumb-item">
                            <NavLink to="/dashboard">
                                <span className="basePath">Dashboard</span>
                            </NavLink>
                        </li>
                        <li className="breadcrumb-item active currentPath">Payouts</li>
                    </ol>
                    <h1 className="page-header">Payouts</h1>
                    {loader ? (
                        <>
                            <div
                                style={{
                                    backgroundColor: "white",
                                    padding: "20px",
                                    borderRadius: "20px",
                                }}
                            >
                                <div className="row w-100">
                                    <div className="mb-3 col-12 text-center">
                                        <div className="row">
                                            <div className="col-xl-4 col-lg-4 col-sm-4 col-12 mb-3">
                                                <div className="ml-0">
                                                    <div className="d-flex">
                                                        <h5 className="mt-2 mr-1">Search: </h5>
                                                        <Search
                                                            onSearch={(value) => {
                                                                setSearch(value);
                                                                setCurrentPage(1);
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-4 col-lg-4 col-sm-4 col-12 mb-3">
                                                <div className="ml-0">
                                                    <div className="d-flex">
                                                        <h5 className="mt-2 mr-1">Stripe Available Balance: ${balance} </h5>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-4 col-lg-4 col-sm-4 col-12 d-flex justify-content-end mb-3">
                                                <div
                                                    style={{
                                                        color: "black",
                                                        fontSize: "12px",
                                                        fontWeight: "300",
                                                        paddingTop: "0px",
                                                        paddingBottom: "0px",
                                                    }}
                                                    className="align-self-center"
                                                >
                                                    <b>Rows per page :&nbsp;</b>
                                                </div>
                                                <div className="align-self-center">
                                                    <Dropdown>
                                                        <Dropdown.Toggle
                                                            variant="none"
                                                            id="dropdown-basic"
                                                            style={{
                                                                cursor: "auto",
                                                                backgroundColor: "white",
                                                                borderColor: "#d5dbe0",
                                                                paddingBottom: "3px",
                                                                paddingTop: "3px",
                                                            }}
                                                        >
                                                            {limit}&nbsp;<i className="fa fa-caret-down"></i>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            {limit !== 10 ? (
                                                                <>
                                                                    <Dropdown.Item
                                                                        onClick={() => {
                                                                            setlimit(10);
                                                                        }}
                                                                    >
                                                                        10
                                                                    </Dropdown.Item>
                                                                </>
                                                            ) : null}

                                                            {limit !== 20 ? (
                                                                <>
                                                                    <Dropdown.Item
                                                                        onClick={() => {
                                                                            setlimit(20);
                                                                        }}
                                                                    >
                                                                        20
                                                                    </Dropdown.Item>
                                                                </>
                                                            ) : null}

                                                            {limit !== 30 ? (
                                                                <>
                                                                    <Dropdown.Item
                                                                        onClick={() => {
                                                                            setlimit(30);
                                                                        }}
                                                                    >
                                                                        30
                                                                    </Dropdown.Item>
                                                                </>
                                                            ) : null}

                                                            {limit !== 50 ? (
                                                                <>
                                                                    <Dropdown.Item
                                                                        onClick={() => {
                                                                            setlimit(50);
                                                                        }}
                                                                    >
                                                                        50
                                                                    </Dropdown.Item>
                                                                </>
                                                            ) : null}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row ">
                                            <div className="col-12">
                                                <div className="table-responsive">
                                                    <Table striped bordered hover>
                                                        <thead>
                                                            <TableHeader
                                                                headers={Header}
                                                                onSorting={(field, order) =>
                                                                    setSorting({ field, order })
                                                                }
                                                            />
                                                        </thead>
                                                        <tbody>
                                                            {commentsData.map((e, i) => (
                                                                <tr>
                                                                    <td>{e.sr_no}</td>
                                                                    {/* <td>
                                    {e?.image?.match("undefined") !=
                                      "undefined" ? (
                                      e?.image != null ? (
                                        <img
                                          src={e.image}
                                          width="70px"
                                          height="60px"
                                          alt="restoImg"
                                        />
                                      ) : (
                                        <img
                                          src="./assets/img/icon/restaurantIcon.png"
                                          width="50px"
                                          height="50px"
                                          alt="customer"
                                        />
                                      )
                                    ) : (
                                      <img
                                        src="./assets/img/icon/restaurantIcon.png"
                                        width="50px"
                                        height="50px"
                                        alt="customer"
                                      />
                                    )}
                                  </td> */}
                                                                    <td>{e.restaurant_name}</td>
                                                                    <td>
                                                                        ${e.amount}
                                                                    </td>
                                                                    <td>
                                                                        {e?.status === 2 ? (
                                                                            <><span
                                                                                style={{ color: "red", fontSize: "15px" }}
                                                                            >Rejected</span>
                                                                                <i
                                                                                    class="fa fa-exclamation-circle"
                                                                                    style={{
                                                                                        color: "grey ",
                                                                                        fontSize: "12px",
                                                                                        marginLeft: "5px",
                                                                                        cursor: "pointer"
                                                                                    }}
                                                                                    title={e?.reason}
                                                                                ></i></>
                                                                        ) : e?.status === 0 ? (
                                                                            <div style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
                                                                                <i
                                                                                    class="fa fa-check-square"
                                                                                    style={{
                                                                                        color: "green",
                                                                                        fontSize: "23px",
                                                                                        cursor: "pointer"
                                                                                    }}
                                                                                    onClick={() => handleRest(e?.id, 1)}
                                                                                ></i>
                                                                                <i
                                                                                    class="fa fa-window-close"
                                                                                    style={{
                                                                                        color: "red",
                                                                                        fontSize: "23px",
                                                                                        cursor: "pointer"
                                                                                    }}
                                                                                    onClick={() => handleRest(e?.id, 2)}
                                                                                ></i>
                                                                            </div>
                                                                        ) : (<span
                                                                            style={{
                                                                                color: "green",
                                                                                fontSize: "15px",
                                                                            }}
                                                                        >Approved</span>)}
                                                                    </td>
                                                                    <td>{e.createdAt}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </Table>
                                                    {commentsData.length == 0 ? (
                                                        <div className="row d-flex justify-content-center w-100">
                                                            <div className="mx-auto  d-flex justify-content-center w-100">
                                                                <img
                                                                    src="./assets/img/icon/no-location.png"
                                                                    className="form-img__img-preview"
                                                                    style={{ width: "100px", height: "100px" }}
                                                                    alt=""
                                                                />
                                                            </div>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="mt-2 d-flex justify-content-sm-center justify-content-xs-center justify-content-lg-end"
                                            style={{
                                                overflowX: "auto",
                                            }}
                                        >
                                            <Pagination
                                                total={totalItems}
                                                itemsPerPage={limit}
                                                currentPage={currentPage}
                                                onPageChange={(page) => setCurrentPage(page)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <Spinner />
                    )}
                </div>
            </div>
        </>
    );
};

export default Payout;