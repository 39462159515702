import React, { useState, useEffect, useMemo, useRef } from "react";
import { NavLink } from "react-router-dom";
import { TableHeader, Pagination, Search } from "../Table";
import { Dropdown, Table } from "react-bootstrap";
import Loader from "../include/Loader";
import Menu from "../include/Menu";
import Spinner from "../include/Spinner";
import Footer from "../include/Footer";
import axios from "axios";
import { toast } from "react-toastify";
import CropEasy from "../CropImage/CropEasy";
import moment from "moment";
import isUrl from "is-url";

export default function Banners() {
  const [banners, setBanners] = useState([]);
  const [addPicture, setAddPicture] = useState(false);
  const [picture, setPicture] = useState({});
  const [pictureName, setPictureName] = useState({});
  const [link, setLink] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [error, setError] = useState({});
  const [disable, setDisable] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const [limit, setlimit] = useState(10);
  const [loader, setLoader] = useState(false);
  const [err, setErr] = useState();
  const ref = useRef();
  const [photoURL, setPhotoURL] = useState('');
  const [file, setFile] = useState(null);
  const [openCrop, setOpenCrop] = useState(false);

  const reset = () => {
    ref.current.value = "";
  };
  const Header = [
    {
      name: "Sr. NO.",
      field: "sr_no",
      sortable: false,
    },
    {
      name: "Image",
      field: "image",
      sortable: false,
    },
    {
      name: "Start Date",
      field: "start_date",
      sortable: false,
    },
    {
      name: "End Date",
      field: "end_date",
      sortable: false,
    },
    {
      name: "Info",
      field: "link",
      sortable: false,
    },
    {
      name: "Delete",
      field: "delete",
      sortable: false,
    },
  ];
  const getBanners = (draggedIndex = "") => {
    const myurl = `${process.env.REACT_APP_URL}api/admin/banners-list`;
    var bodyFormData = new URLSearchParams();
    bodyFormData.append("auth_code", "Brud#Cust$&$Resto#MD");
    bodyFormData.append('from', fromindex);
    bodyFormData.append('to', (draggedIndex != '') ? draggedIndex : 0);

    axios({
      method: "post",
      url: myurl,
      data: bodyFormData,
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    })
      .then((response) => {
        console.log(response["data"]["data"]);
        var indexedData = response["data"]["data"]?.map((e, i) => {
          e = { ...e };
          e = { ...e, sr_no: i + 1 };

          return e;
        });
        setLoader(true);
        setBanners(indexedData);
      })
      .catch((error) => {
        setLoader(true);
        console.log("Errors", error);
      });
  };

  useEffect(() => {
    getBanners();

    document.getElementById("page-loader").style.display = "none";

    var element = document.getElementById("page-container");
    element.classList.add("show");
  }, []);

  function deleteBanner(id) {
    if (window.confirm("Are you sure you wish to delete this item?")) {
      const myurl = `${process.env.REACT_APP_URL}api/admin/delete-banner`;
      var bodyFormData = new URLSearchParams();
      bodyFormData.append("auth_code", "Brud#Cust$&$Resto#MD");
      bodyFormData.append("banner_id", id);
      axios({
        method: "post",
        url: myurl,
        data: bodyFormData,
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      })
        .then((response) => {
          console.log("delete", response);
          getBanners();
        })
        .catch((error) => {
          console.log("Errors", error);
        });
    }
  }

  const uploadPicture = async (e) => {
    e.preventDefault();
    setDisable(true);

    let imgs = e.target.files[0];
    if (
      imgs.type === "image/jpg" ||
      imgs.type === "image/jpeg" ||
      imgs.type === "image/png"
    ) {
      var img = new Image();
      img.src = URL.createObjectURL(imgs);
      img.onload = function () {
        if (this.width < 440 || this.height < 400) {
          // ref.current.value = "";
          setError({
            img_error: `Image dimension must be 400 x 400 but it is ${this.width} x ${this.height}`,
          });
          setDisable(false);
        } else {
          setFile(imgs);
          setPhotoURL(URL.createObjectURL(imgs));
          setError({ img_error: "" });
          setPicture(e.target.files[0]);
          setAddPicture(true);
          setOpenCrop(true);
        }
      };
    } else {
      // ref.current.value = "";
      setError({
        img_error: `You have selected incorrect format of Image(jpp,jpeg,png)`,
      });
      setDisable(false);
    }
  };

  function blobToFile(theBlob, fileName) {
    return new File([theBlob], fileName, { lastModified: new Date().getTime(), type: theBlob.type })
  }

  const croppedImage = (file) => {
    var myFile = blobToFile(file, file.name);
    const myurl = `${process.env.REACT_APP_URL}api/admin/upload-img`;
    var bodyFormData = new FormData();
    bodyFormData.append("auth_code", "Brud#Cust$&$Resto#MD");
    bodyFormData.append("image", myFile);

    //alert(bodyFormData);
    axios({
      method: "post",
      url: myurl,
      data: bodyFormData,
    })
      .then((result) => {
        console.log("Success:=====", result);
        console.log("111111", banners);
        setPictureName(result?.data?.data?.filepath_url);
        setPicture(result?.data?.data?.filepath_url);
        setDisable(false);
        //getBanners();
      })
      .catch((error) => {
        console.error("Error:", error);
        setDisable(false);
        setPicture();
        setAddPicture(false);
      });
  };

  const validateImage = () => {
    let isValid = true;
    let error = {};
    if (addPicture === false) {
      isValid = false;
      error["img_error"] = "Please choose an image";
    }
    if (!link) {
      isValid = false;
      error["url_error"] = "Please Enter an URL";
    } else if (!isUrl(link)) {
      isValid = false;
      error["url_error"] = "Please Enter an valid  URL";
    }
    if (!startDate) {
      isValid = false;
      error["start_date_error"] = "Please Enter start date";
    }
    if (!endDate) {
      isValid = false;
      error["end_date_error"] = "Please Enter end date";
    }
    console.log(isValid);
    setError(error);
    return isValid;
  };
  const setImageAction = async (event) => {
    event.preventDefault();
    if (validateImage()) {
      const myurl = `${process.env.REACT_APP_URL}api/admin/add-banner`;
      var bodyFormData = new URLSearchParams();
      bodyFormData.append("auth_code", "Brud#Cust$&$Resto#MD");
      bodyFormData.append("image", picture);
      bodyFormData.append("link", link);
      bodyFormData.append("start_date", startDate);
      bodyFormData.append("end_date", endDate);

      axios({
        method: "post",
        url: myurl,
        data: bodyFormData,
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      })
        .then((result) => {
          console.log("Success::::::::", result);
          getBanners();
          setPicture({});
          setPictureName({});
          setLink("");
          setStartDate("");
          setEndDate("");
          reset();
          toast.success("Banner added Successfully");
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };
  const commentsData = useMemo(() => {
    let computedComments = banners;
    if (search) {
      computedComments = computedComments.filter((banners) =>
        banners.order_no.toString().toLowerCase().includes(search.toLowerCase())
      );
    }
    setTotalItems(computedComments.length);

    //Sorting comments
    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * limit,
      (currentPage - 1) * limit + limit
    );
  }, [currentPage, search, sorting, banners, limit]);
  const [fromindex, setFromindex] = useState(0);
  const [draggedIndex, setDraggedIndex] = useState(null);

  const handleDragStart = (index) => {
    //console.log('selected index -- ', index);    
    setDraggedIndex(index);
    setFromindex(index);
  };

  const handleDragOver = (overIndex) => {
    if (draggedIndex === null || overIndex === draggedIndex) return;

    const updatedRows = [...banners];
    const draggedRow = updatedRows[draggedIndex];
    const adjustedIndex = overIndex > draggedIndex ? overIndex + 1 : overIndex;

    updatedRows.splice(draggedIndex, 1);
    updatedRows.splice(adjustedIndex, 0, draggedRow);

    setBanners(updatedRows);
    setDraggedIndex(adjustedIndex);
  };

  const handleDragEnd = () => {
    //console.log(draggedIndex);
    if (draggedIndex === null) return;

    //console.log('change complete...', draggedIndex);

    setDraggedIndex(null);
    getBanners(draggedIndex); // Pass draggedIndex to the function
  };
  return (
    <>
      <Loader />
      <CropEasy
        photoURL={photoURL}
        file={file}
        activeModal={openCrop}
        setActiveModal={() => setOpenCrop(false)}
        croppedImage={croppedImage}
      />
      <div
        id="page-container"
        className="fade page-sidebar-fixed page-header-fixed"
      >
        <Menu />
        <div id="content" className="content">
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item">
              <NavLink to="/dashboard">
                <span className="basePath">Dashboard</span>
              </NavLink>
            </li>
            <li className="breadcrumb-item active currentPath">Banners</li>
          </ol>
          <h1 className="page-header">Banners</h1>
          {loader ? (
            <>
              <button
                type="button"
                className="btn btn-secondary mb-3"
                data-toggle="collapse"
                data-target="#collapseExample"
                aria-expanded="false"
                aria-controls="collapseExample"
                style={{ borderRadius: "20px" }}
              >
                ADD BANNER
              </button>
              <div
                className="collapse"
                id="collapseExample"
                style={{ borderRadius: "20px" }}
              >
                <div
                  className="card card-body"
                  style={{ borderRadius: "20px" }}
                >
                  <form onSubmit={setImageAction} encType="multipart/form-data">
                    <label
                      for="exampleInputPassword1"
                      style={{ fontSize: "16px", fontWeight: "600" }}
                    >
                      Image:
                    </label>
                    <br />
                    <input
                      type="file"
                      name="image"
                      ref={ref}
                      onChange={uploadPicture}
                      style={{ marginLeft: "-10px" }}
                    />
                    <div className="text-danger">{error.img_error}</div>
                    <label
                      for="exampleInputPassword1"
                      style={{ fontSize: "16px", fontWeight: "600" }}
                    >
                      Link :
                    </label>
                    <input
                      type="text"
                      className="form-control ml-0 w-25"
                      value={link}
                      placeholder="Enter banner URL"
                      onChange={(e) => setLink(e.target.value)}
                      style={{ borderRadius: "20px" }}
                    />
                    <div className="text-danger">{error.url_error}</div>
                    <label
                      for="exampleInputPassword1"
                      style={{ fontSize: "16px", fontWeight: "600" }}
                    >
                      Start Date :
                    </label>
                    <input
                      type="date"
                      className="form-control ml-0 w-25"
                      value={startDate}
                      placeholder="Enter date"
                      onChange={(e) => setStartDate(e.target.value)}
                      max={endDate}
                      min={new Date().toISOString().split('T')[0]}
                      style={{ borderRadius: "20px" }}
                    />
                    <div className="text-danger">{error.start_date_error}</div>
                    <label
                      for="exampleInputPassword1"
                      style={{ fontSize: "16px", fontWeight: "600" }}
                    >
                      End Date :
                    </label>
                    <input
                      type="date"
                      className="form-control ml-0 w-25"
                      value={endDate}
                      placeholder="Enter date"
                      min={startDate}
                      onChange={(e) => setEndDate(e.target.value)}
                      style={{ borderRadius: "20px" }}
                    />
                    <div className="text-danger">{error.end_date_error}</div>
                    <br />
                    <button
                      className="btn btn-success save-btp"
                      type="submit"
                      name="upload"
                      style={{
                        width: "120px",
                        borderRadius: "5px",
                        fontSize: "15px",
                        borderRadius: "20px",
                      }}
                      disabled={disable}
                    >
                      {disable ? "Processing..." : "Upload"}
                    </button>
                  </form>
                </div>
              </div>
              <br />

              <div
                style={{
                  backgroundColor: "white",
                  padding: "20px",
                  borderRadius: "20px",
                }}
              >
                <div className="row w-100">
                  <div className="mb-3 col-12 text-center">
                    <div className="row">
                      <div className="col-xl-6 col-lg-6 col-sm-6 col-12 mb-3">
                        <div className="ml-0">
                          <div className="d-flex">
                            <h5 className="mt-2 mr-1">Search: </h5>
                            <Search
                              onSearch={(value) => {
                                setSearch(value);
                                setCurrentPage(1);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-sm-6 col-12 d-flex justify-content-end mb-3">
                        <div
                          style={{
                            color: "black",
                            fontSize: "12px",
                            fontWeight: "300",
                            paddingTop: "0px",
                            paddingBottom: "0px",
                          }}
                          className="align-self-center"
                        >
                          <b>Rows per page :&nbsp;</b>
                        </div>
                        <div className="align-self-center">
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="none"
                              id="dropdown-basic"
                              style={{
                                cursor: "auto",
                                backgroundColor: "white",
                                borderColor: "#d5dbe0",
                                paddingBottom: "3px",
                                paddingTop: "3px",
                              }}
                            >
                              {limit}&nbsp;<i className="fa fa-caret-down"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {limit !== 10 ? (
                                <>
                                  <Dropdown.Item
                                    onClick={() => {
                                      setlimit(10);
                                    }}
                                  >
                                    10
                                  </Dropdown.Item>
                                </>
                              ) : null}

                              {limit !== 20 ? (
                                <>
                                  <Dropdown.Item
                                    onClick={() => {
                                      setlimit(20);
                                    }}
                                  >
                                    20
                                  </Dropdown.Item>
                                </>
                              ) : null}

                              {limit !== 30 ? (
                                <>
                                  <Dropdown.Item
                                    onClick={() => {
                                      setlimit(30);
                                    }}
                                  >
                                    30
                                  </Dropdown.Item>
                                </>
                              ) : null}

                              {limit !== 50 ? (
                                <>
                                  <Dropdown.Item
                                    onClick={() => {
                                      setlimit(50);
                                    }}
                                  >
                                    50
                                  </Dropdown.Item>
                                </>
                              ) : null}
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    </div>

                    <div className="row ">
                      <div className="col-12">
                        <div className="table-responsive">
                          <Table striped bordered hover>
                            <thead>
                              <TableHeader
                                headers={Header}
                                onSorting={(field, order) =>
                                  setSorting({ field, order })
                                }
                              />
                            </thead>
                            <tbody>
                              {commentsData.map((e, i) => (
                                <tr
                                  draggable
                                  className={i === draggedIndex ? 'dragged' : ''}
                                  onDragStart={() => handleDragStart(i)}
                                  onDragOver={() => handleDragOver(i)}
                                  onDragEnd={handleDragEnd}>
                                  <td>{e.order_no}</td>
                                  <td>
                                    <img
                                      src={e.image}
                                      style={{ width: '200px', height: '100px', objectFit: 'cover' }}
                                      alt="Img"
                                    />
                                  </td>
                                  <td>
                                    {moment(e?.start_date).format("MM/DD/yyyy")}
                                  </td>
                                  <td>
                                    {moment(e?.end_date).format("MM/DD/yyyy")}
                                  </td>
                                  <td>
                                    <a href={e.link} target="_blank">
                                      <i class="fa fa-link edit"></i>
                                    </a>
                                  </td>
                                  <td>
                                    <i
                                      className="fa fa-trash delete"
                                      style={{
                                        cursor: "pointer",
                                        marginLeft: "13px",
                                      }}
                                      onClick={() => deleteBanner(e._id)}
                                    ></i>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                          {commentsData.length == 0 ? (
                            <div className="row d-flex justify-content-center w-100">
                              <div className="mx-auto  d-flex justify-content-center w-100">
                                <img
                                  src="./assets/img/icon/no-location.png"
                                  className="form-img__img-preview"
                                  style={{ width: "100px", height: "100px" }}
                                  alt=""
                                />
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div
                      className="mt-2 d-flex justify-content-sm-center justify-content-xs-center justify-content-lg-end"
                      style={{
                        overflowX: "auto",
                      }}
                    >
                      <Pagination
                        total={totalItems}
                        itemsPerPage={limit}
                        currentPage={currentPage}
                        onPageChange={(page) => setCurrentPage(page)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <Spinner />
          )}

          <Footer />
        </div>
      </div>
    </>
  );
}
