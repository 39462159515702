import React, { useState, useEffect, useMemo } from "react";
import { NavLink, useHistory } from "react-router-dom";
// import { toast } from "react-toastify";
import { TableHeader, Pagination, Search } from "../Table";
import { Dropdown, Table } from "react-bootstrap";
import Loader from "../include/Loader";
import Menu from "../include/Menu";
import Spinner from "../include/Spinner";
// import Footer from "./include/Footer";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";
import { ToastContainer, toast } from "react-toastify";

const Tax = () => {
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });
    const [limit, setlimit] = useState(10);
    const [loader, setLoader] = useState(true);
    const [restoreModal, setRestoreModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [text, setText] = useState(null);
    const [error, setError] = useState({});
    const [showAddEdit, setShowAddEdit] = useState(false);
    const [selected, setSelected] = useState({ state: '', tax: null });
    const [id, setId] = useState(null);
    const [disable, setDisable] = useState(false);
    const Header = [
        {
            name: "Sr. NO.",
            field: "sr_no",
            sortable: false,
        },
        // {
        //   name: "Image",
        //   field: "image",
        //   sortable: false,
        // },
        {
            name: "State",
            field: "state",
            sortable: true,
        },
        {
            name: "Tax",
            field: "tax",
            sortable: false,
        },
        {
            name: "Status",
            field: "status",
            sortable: false,
        },
        {
            name: "Action",
            field: "action",
            sortable: false,
        },
    ];
    let history = useHistory();

    const handleChange = (e) => {
        const { name, value } = e?.target;
        setSelected({ ...selected, [name]: value });
    }

    const validate = () => {
        let isValid = true;
        let error = {};
        const regex = /^(?:[1-9][0-9]|[1-9])\.\d{2}$/;
        const regex2 = /^[a-zA-Z]{1,30}$/;
        if (!selected?.state?.trim()) {
            isValid = false;
            error["state_error"] = "Please enter state";
        } else if (!regex2.test(selected?.state?.trim())) {
            isValid = false;
            error["state_error"] = "Please enter valid state";
        }
        if (!selected?.tax) {
            isValid = false;
            error["tax_error"] = "Please enter tax";
        } else if (!regex.test(selected.tax)) {
            isValid = false;
            error["tax_error"] = "Please enter valid tax";
        }
        console.log(isValid);
        setError(error);
        return isValid;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            setDisable(true);
            const myurl = `${process.env.REACT_APP_URL}api/admin/manage-tax`;
            var bodyFormData = new URLSearchParams();
            bodyFormData.append("auth_code", "Brud#Cust$&$Resto#MD");
            bodyFormData.append("state", selected.state?.trim());
            bodyFormData.append("tax", selected.tax?.trim());
            if (id) {
                bodyFormData.append("tax_id", id);
            }
            axios({
                method: "post",
                url: myurl,
                data: bodyFormData,
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
            })
                .then((result) => {
                    setSelected({ state: "", tax: null });
                    setId("");
                    setShowAddEdit(false);
                    setDisable(false);
                    toast.success(result?.data?.message);
                    getList();
                })
                .catch((error) => {
                    console.error("Error:", error);
                });
        }
    }
    const [list, setList] = useState([]);
    const getList = () => {
        const myurl = `${process.env.REACT_APP_URL}api/admin/tax-list`;
        var bodyFormData = new URLSearchParams();
        bodyFormData.append("auth_code", "Brud#Cust$&$Resto#MD");

        axios({
            method: "post",
            url: myurl,
            data: bodyFormData,
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
        })
            .then((response) => {
                // console.log(response["data"]["data"]);
                // console.log(response);
                var indexedData = response["data"]["data"];
                setLoader(true);
                setList(indexedData);
                // setBalance(response["data"]["amount"])
            })
            .catch((error) => {
                setLoader(true);
                console.log("Errors", error);
            });
    };

    useEffect(() => {
        document.getElementById("page-loader").style.display = "none";

        var element = document.getElementById("page-container");
        element.classList.add("show");
        getList();
    }, []);
    const handleEdit = (val) => {
        setId(val?.id);
        setSelected({
            state: val?.state,
            tax: val?.tax
        });
        setShowAddEdit(true);
    }

    const commentsData = useMemo(() => {
        let computedComments = list;

        if (search) {
            console.log(computedComments)
            computedComments = computedComments
                .filter(
                    (taxList) =>
                        taxList?.state
                            ?.toLowerCase()
                            ?.includes(search.toLowerCase())
                    // ||
                    // (!isNaN(search) && taxList?.tax?.includes(search))
                );
        }
        setTotalItems(computedComments.length);

        //Sorting comments
        if (sorting.field) {
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedComments = computedComments.sort(
                (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
            );
        }

        //Current Page slice
        return computedComments.slice(
            (currentPage - 1) * limit,
            (currentPage - 1) * limit + limit
        );
    }, [currentPage, search, sorting, list, limit]);

    const handleCancel = () => {
        setDeleteModal(false);
        setRestoreModal(false);
        setId(null);
    }


    const handleRestResponse = () => {
        const myurl = `${process.env.REACT_APP_URL}api/admin/delete-restore-tax`;
        var bodyFormData = new URLSearchParams();
        bodyFormData.append("auth_code", "Brud#Cust$&$Resto#MD");
        bodyFormData.append("tax_id", id);

        axios({
            method: "post",
            url: myurl,
            data: bodyFormData,
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
        })
            .then((response) => {
                handleCancel();
                getList();
                toast.success(response.data.message)
            })
            .catch((error) => {
                console.log("Errors", error);
                handleCancel();
                toast.error(error.response.data.message)
            });
    }
    return (
        <>
            {deleteModal &&
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Yes, disable it!"
                    confirmBtnBsStyle="danger"
                    title="Are you sure want to disable it?"
                    onConfirm={handleRestResponse}
                    onCancel={handleCancel}
                    focusCancelBtn
                >
                </SweetAlert>}
            {restoreModal &&
                <SweetAlert
                    success
                    showCancel
                    confirmBtnText="Yes, restore it!"
                    confirmBtnBsStyle="danger"
                    title="Are you sure want to restore it?"
                    onConfirm={handleRestResponse}
                    onCancel={handleCancel}
                    confirmBtnStyle={{ backgroundColor: "green", borderColor: "green" }}
                >
                </SweetAlert>}
            <ToastContainer />
            <Loader />

            <div
                id="page-container"
                className="fade page-sidebar-fixed page-header-fixed"
            >
                <Menu />

                <div id="content" className="content">
                    <ol className="breadcrumb float-xl-right">
                        <li className="breadcrumb-item">
                            <NavLink to="/dashboard">
                                <span className="basePath">Dashboard</span>
                            </NavLink>
                        </li>
                        <li className="breadcrumb-item active currentPath">Tax</li>
                    </ol>
                    <h1 className="page-header">Tax</h1>
                    {loader ? (
                        <>
                            <button
                                type="button"
                                className="btn btn-secondary mb-3"
                                style={{ borderRadius: "20px" }}
                                disabled={disable}
                                onClick={() => { setId(null); setSelected({ tax: null, state: '' }); setShowAddEdit(!showAddEdit) }}
                            >
                                ADD TAX
                            </button>
                            {showAddEdit && <div
                                className="card card-body"
                                style={{ borderRadius: "20px" }}
                            >
                                <form onSubmit={handleSubmit}>
                                    <div className="row align-items-start w-50">
                                        <div className="col-md-6">
                                            <label
                                                for="exampleInputPassword1"
                                                style={{ fontSize: "16px", fontWeight: "600" }}
                                            >
                                                State :
                                            </label>

                                            <br />
                                            <input
                                                type="text"
                                                className="form-control ml-0 "
                                                style={{ borderRadius: "20px" }}
                                                name="state"
                                                onChange={handleChange}
                                                value={selected.state}
                                            />
                                            <div className="text-danger">{error.state_error}</div>
                                        </div>
                                        <div className="col-md-6">
                                            <label
                                                for="exampleInputPassword1"
                                                style={{ fontSize: "16px", fontWeight: "600" }}
                                            >
                                                Tax :
                                            </label>

                                            <br />
                                            <input
                                                type="text"
                                                className="form-control ml-0"
                                                style={{ borderRadius: "20px" }}
                                                name="tax"
                                                onChange={handleChange}
                                                value={selected.tax}
                                            />
                                            <div className="text-danger">{error.tax_error}</div>
                                        </div>
                                        <div className="col-md-4 mb-2">
                                            <button type="submit" className="btn btn-success save-btp"
                                                disabled={disable}
                                                style={{
                                                    width: "120px",
                                                    borderRadius: "5px",
                                                    fontSize: "15px",
                                                    borderRadius: "20px",
                                                }}>{disable ? "Processing.." : (id ? "Update" : "Submit")}</button>
                                        </div>
                                    </div>
                                </form>
                            </div>}
                            <div
                                style={{
                                    backgroundColor: "white",
                                    padding: "20px",
                                    borderRadius: "20px",
                                    marginTop: "20px"
                                }}
                            >
                                <div className="row w-100">
                                    <div className="mb-3 col-12 text-center">
                                        <div className="row">
                                            <div className="col-xl-6 col-lg-6 col-sm-4 col-12 mb-3">
                                                <div className="ml-0">
                                                    <div className="d-flex">
                                                        <h5 className="mt-2 mr-1">Search: </h5>
                                                        <Search
                                                            onSearch={(value) => {
                                                                setSearch(value);
                                                                setCurrentPage(1);
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-6 col-lg-6 col-sm-4 col-12 d-flex justify-content-end mb-3">
                                                <div
                                                    style={{
                                                        color: "black",
                                                        fontSize: "12px",
                                                        fontWeight: "300",
                                                        paddingTop: "0px",
                                                        paddingBottom: "0px",
                                                    }}
                                                    className="align-self-center"
                                                >
                                                    <b>Rows per page :&nbsp;</b>
                                                </div>
                                                <div className="align-self-center">
                                                    <Dropdown>
                                                        <Dropdown.Toggle
                                                            variant="none"
                                                            id="dropdown-basic"
                                                            style={{
                                                                cursor: "auto",
                                                                backgroundColor: "white",
                                                                borderColor: "#d5dbe0",
                                                                paddingBottom: "3px",
                                                                paddingTop: "3px",
                                                            }}
                                                        >
                                                            {limit}&nbsp;<i className="fa fa-caret-down"></i>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            {limit !== 10 ? (
                                                                <>
                                                                    <Dropdown.Item
                                                                        onClick={() => {
                                                                            setlimit(10);
                                                                        }}
                                                                    >
                                                                        10
                                                                    </Dropdown.Item>
                                                                </>
                                                            ) : null}

                                                            {limit !== 20 ? (
                                                                <>
                                                                    <Dropdown.Item
                                                                        onClick={() => {
                                                                            setlimit(20);
                                                                        }}
                                                                    >
                                                                        20
                                                                    </Dropdown.Item>
                                                                </>
                                                            ) : null}

                                                            {limit !== 30 ? (
                                                                <>
                                                                    <Dropdown.Item
                                                                        onClick={() => {
                                                                            setlimit(30);
                                                                        }}
                                                                    >
                                                                        30
                                                                    </Dropdown.Item>
                                                                </>
                                                            ) : null}

                                                            {limit !== 50 ? (
                                                                <>
                                                                    <Dropdown.Item
                                                                        onClick={() => {
                                                                            setlimit(50);
                                                                        }}
                                                                    >
                                                                        50
                                                                    </Dropdown.Item>
                                                                </>
                                                            ) : null}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row ">
                                            <div className="col-12">
                                                <div className="table-responsive">
                                                    <Table striped bordered hover>
                                                        <thead>
                                                            <TableHeader
                                                                headers={Header}
                                                                onSorting={(field, order) =>
                                                                    setSorting({ field, order })
                                                                }
                                                            />
                                                        </thead>
                                                        <tbody>
                                                            {commentsData.map((el, i) => (
                                                                <tr>
                                                                    <td>{(limit * (currentPage - 1)) + (i + 1)}</td>
                                                                    <td>{el.state}</td>
                                                                    <td>
                                                                        {el.tax}%
                                                                    </td>
                                                                    <td>
                                                                        {el?.status ? <i
                                                                            class="fa fa-universal-access"
                                                                            style={{
                                                                                color: "green",
                                                                                fontSize: "23px",
                                                                            }}
                                                                        /> :
                                                                            <i
                                                                                class="fa fa-user-slash"
                                                                                style={{ color: "red", fontSize: "18px" }}
                                                                            ></i>}
                                                                    </td>
                                                                    <td>
                                                                        {el?.status ? <>
                                                                            <i className="fa fa-pen mr-3" style={{ color: "green", cursor: "pointer" }} onClick={() => handleEdit(el)} />
                                                                            <i className="fa fa-ban" style={{ color: "red", cursor: "pointer" }} onClick={() => { setId(el?.id); setDeleteModal(true); }} />
                                                                        </> : <i className="fa fa-undo" style={{ color: "green", cursor: "pointer" }} onClick={() => { setId(el?.id); setRestoreModal(true); }} />}
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </Table>
                                                    {commentsData.length == 0 ? (
                                                        <div className="row d-flex justify-content-center w-100">
                                                            <div className="mx-auto  d-flex justify-content-center w-100">
                                                                <img
                                                                    src="./assets/img/icon/no-location.png"
                                                                    className="form-img__img-preview"
                                                                    style={{ width: "100px", height: "100px" }}
                                                                    alt=""
                                                                />
                                                            </div>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="mt-2 d-flex justify-content-sm-center justify-content-xs-center justify-content-lg-end"
                                            style={{
                                                overflowX: "auto",
                                            }}
                                        >
                                            <Pagination
                                                total={totalItems}
                                                itemsPerPage={limit}
                                                currentPage={currentPage}
                                                onPageChange={(page) => setCurrentPage(page)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <Spinner />
                    )}
                </div>
            </div>
        </>
    );
};

export default Tax;