import { Button, DialogContent } from '@material-ui/core'
import { Box, DialogActions, Slider, Typography } from '@mui/material';
import { Fragment, useState } from 'react'
import Cropper from 'react-easy-crop'
import { Close, Crop } from '@mui/icons-material';
import { Modal } from 'react-bootstrap';
import getCroppedImg from './cropImage';

// eslint-disable-next-line react/prop-types
const CropEasy = ({ photoURL, croppedImage, setActiveModal, activeModal, imgType }) => {
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [rotation, setRotation] = useState(0);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

    const cropComplete = (croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }

    const zoomPercentage = (value) => {
        return `${Math.round(value * 100)}%`
    }

    const cropImage = async () => {
        try {
            const { file, url } = await getCroppedImg(photoURL, croppedAreaPixels, rotation)
            croppedImage(file, url);
            setActiveModal();
        } catch {
            console.log("error");
        }
    }
    return (
        <Fragment>
            <Modal
                className='fade'
                id='cropModal'
                show={activeModal}
                onHide={setActiveModal}
                size='md'
                backdrop='static'
                keyboard={false}
            >
                <Modal.Header>
                    <h4 style={{ marginBottom: "0px" }}>Crop Image</h4>
                    <Close onClick={setActiveModal} style={{ cursor: 'pointer' }} />
                </Modal.Header>
                <Modal.Body>
                    <DialogContent dividers
                        style={{
                            background: '#333',
                            position: 'relative',
                            height: '300px',
                            width: 'auto',
                        }}
                    >
                        <Cropper
                            image={photoURL}
                            crop={crop}
                            zoom={zoom}
                            rotation={rotation}
                            aspect={16 / 9}
                            onZoomChange={setZoom}
                            onRotationChange={setRotation}
                            onCropChange={setCrop}
                            onCropComplete={cropComplete}
                        />
                    </DialogContent>
                    <DialogActions sx={{ flexDirection: 'column', mx: 3, my: 2 }}>
                        <Box sx={{ width: '100%', mb: 1 }}>
                            <Box>
                                <Typography>Zoom: {zoomPercentage(zoom)}</Typography>
                                <Slider
                                    valueLabelDisplay='auto'
                                    valueLabelFormat={zoomPercentage}
                                    min={1}
                                    max={3}
                                    step={0.1}
                                    value={zoom}
                                    onChange={(e, zoom) => setZoom(zoom)}
                                />
                            </Box>
                            <Box>
                                <Typography>Rotation: {rotation}</Typography>
                                <Slider
                                    valueLabelDisplay='auto'
                                    min={0}
                                    max={360}
                                    value={rotation}
                                    onChange={(e, rotation) => setRotation(rotation)}
                                />
                            </Box>
                        </Box>
                        <Box>
                            <Button
                                variant='contained'
                                startIcon={<Crop />}
                                onClick={cropImage}
                            >
                                Crop
                            </Button>
                        </Box>
                    </DialogActions>
                </Modal.Body>
            </Modal>
        </Fragment>
    )
}

export default CropEasy