import React, { useState, useEffect, useMemo } from "react";
import { NavLink } from "react-router-dom";
import Loader from "../include/Loader";
import Menu from "../include/Menu";
import Footer from "../include/Footer";
import axios from "axios";
import { TableHeader, Pagination, Search } from "../Table";
import { Dropdown, Table } from "react-bootstrap";
import Spinner from "../include/Spinner";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";

export default function ChangeState() {
  let history = useHistory();
  const location = useLocation();
  console.log(location.state);
  var data = location.state;
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    document.getElementById("page-loader").style.display = "none";

    var element = document.getElementById("page-container");
    element.classList.add("show");
  }, []);
  console.log(data?.e?.id);
  console.log((data?.month < 10 ? "0" : "") + data?.month);
  console.log(data?.year);
  const formSubmit = (e) => {
    e.preventDefault();
    setLoader(false);

    const myurl = `${process.env.REACT_APP_URL}api/admin/restaurants-monthly-payment-pay`;
    var bodyFormData = new URLSearchParams();
    bodyFormData.append("auth_code", "Brud#Cust$&$Resto#MD");
    bodyFormData.append("restaurant_id", data?.e?.id);
    bodyFormData.append("month", (data?.month < 10 ? "0" : "") + data?.month);
    bodyFormData.append("year", data?.year);

    axios({
      method: "post",
      url: myurl,
      data: bodyFormData,
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    })
      .then((response) => {
        console.log(response["data"]["data"]);

        if (response?.data?.sucecess) {
          history.push("/report");
        }
      })
      .catch((error) => {
        console.log("Errors", error);
      });
  };

  return (
    <>
      <Loader />

      <div
        id="page-container"
        className="fade page-sidebar-fixed page-header-fixed"
      >
        <Menu />

        <div id="content" className="content">
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item basePath ">
              {/* <a href="javascript:;">User List</a> */}
              <NavLink to={"/report"}>Report</NavLink>
            </li>
            <li className="breadcrumb-item active currentPath">
              Change Status
            </li>
          </ol>
          <h1 className="page-header">
            <i
              className="fa fa-arrow-left edit"
              onClick={useHistory().goBack}
              style={{
                cursor: "pointer",
                fontSize: "20px",
                marginTop: "7px",
                marginRight: "10px",
              }}
            ></i>
            Change Status
          </h1>

          <div className="row">
            <div className="col-xl-6 p-5">
              <div
                className="card "
                style={{
                  height: "auto",
                  padding: "20px",
                  borderRadius: "20px",
                }}
              >
                <div className="mx-auto">
                  <h3 class="card-title mx-auto" style={{ color: "#f55800" }}>
                    Change Status
                  </h3>
                </div>
                <form
                  //onSubmit={(e) => submitHandler(e)}
                  className="profileForm"
                >
                  <div className="mb-1 mt-3">
                    <label for="exampleInputEmail1" className="form-label">
                      Current Status: &nbsp;
                      <b>
                        {data?.e?.status == "Pending" ? (
                          <>
                            <span style={{ color: "red" }}>Pending</span>
                          </>
                        ) : (
                          <>
                            <span style={{ color: "green" }}>Paid</span>
                          </>
                        )}
                      </b>
                    </label>
                  </div>
                  <p className="mb-4">
                    Are you sure, you want to change status?
                  </p>
                  <button
                    type="submit"
                    className="btn m-r-5 profileSubmitBtn pl-3 pr-3"
                    onClick={formSubmit}
                    style={{
                      borderRadius: "20px",
                      backgroundColor: "#f55800",
                      color: "#fff",
                    }}
                  >
                    Yes
                    {/* {disable ? 'Processing...' : 'Submit'} */}
                  </button>
                  <button
                    type="reset"
                    className="btn btn-outline pl-3 pr-3"
                    value="Reset"
                    onClick={useHistory().goBack}
                    style={{
                      borderRadius: "20px",
                      border: "1px solid #f55800",
                      color: "#f55800",
                      backgroundColor: "#fff4ee",
                    }}
                  >
                    No
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
