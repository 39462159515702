import React, { useState, useEffect } from "react";
import { NavLink, Link, useHistory } from "react-router-dom";

import { toast } from "react-toastify";
import axios from "axios";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

function ResetPassword() {
  const history = useHistory();
  const {id} = useParams();
  const [loginInfo, setLoginInfo] = useState({
    new_password: "",
    confirm_password: "",
  });
  const [errors, setErrors] = useState({});
  const [disable, setDisable] = useState(false);
  const [showPassword, setShowPassword] = useState({
    new_password:false,
    confirm_password:false
});

  const InputEvent = (e) => {
    const newLoginInfo = { ...loginInfo };
    newLoginInfo[e.target.name] = e.target.value;
    setLoginInfo(newLoginInfo);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (validate()) {
      setDisable(true);
      const { new_password } = loginInfo;

      const myurl = `${process.env.REACT_APP_URL}api/admin/reset-password`;
      var bodyFormData = new URLSearchParams();
      bodyFormData.append('id', id);
      bodyFormData.append("password", new_password);
      axios({
        method: "post",
        url: myurl,
        data: bodyFormData,
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      })
        .then((response) => {
          //console.log(response);
          if (response.data.sucecess == true) {
            toast.success("Password reset successfully...!");
            history.push(`/brud-admin`);
          } else {
            setDisable(false);
            toast.error(response.data.message);
          }
        })
        .catch((error) => {
          console.log("Errors", error);
          setDisable(false);
          toast.error("Password not reset...!");
        });
    } else {
      setDisable(false);
    }
  };

  const validate = () => {
    let input = loginInfo;

    let errors = {};
    let isValid = true;
    if (!input["new_password"]) {
      isValid = false;
      errors["new_password_err"] = "Please enter new password";
    }
    if (!input["confirm_password"]) {
        isValid = false;
        errors["confirm_password_err"] = "Please enter confirm password";
      }

      if (input["new_password"] !== input["confirm_password"] ) {
        isValid = false;
        errors["confirm_password_err"] = "Password does not match";
      }

    setErrors(errors);
    return isValid;
  };

  useEffect(() => {
    document.getElementById("page-loader").style.display = "none";

    var element = document.getElementById("page-container");
    element.classList.add("show");
  }, []);

  return (
    <>
      <div id="page-loader" className="fade show">
        <span className="spinner"></span>
      </div>

      <div className="login-cover">
        <div
          className="login-cover-image"
          style={{
            backgroundImage: "url(assets/img/login-bg/login-bg-17.jpg)",
          }}
          data-id="login-cover-image"
        ></div>
        <div className="login-cover-bg"></div>
      </div>

      <div id="page-container" className="fade">
        <div
          className="login login-v2"
          data-pageload-addclassName="animated fadeIn"
        >
          <div className="login-header">
            <div className="brand">
              <span className="logo"></span> <b>BRUD</b> Admin
              <small>Reset password for BRUD admin panel</small>
            </div>
            <div className="icon">
              <i className="fa fa-lock"></i>
            </div>
          </div>

          <div className="login-content">
            <form onSubmit={(e) => submitHandler(e)}>
            <div className={`form-group m-b-20 password  ${errors.new_password_err?"pwd-err":""}`}>
                <input
                  type={showPassword?.new_password?"text":"password"}
                  className={`form-control form-control-lg ml-0`}
                  placeholder="New Password"
                  name="new_password"
                  onChange={InputEvent}
                />
                <div className="text-danger">{errors.new_password_err}</div>
                <img className="eye-icon" src={`http://54.151.95.130/assets/img/${showPassword?.new_password?"eye-slash.svg":"eye.svg"}`} style={{cursor:"pointer"}} alt="eye-icon" onClick={() => setShowPassword({...showPassword, new_password:!showPassword?.new_password})}/>
              </div>
              <div className={`form-group m-b-20 password  ${errors.confirm_password_err?"pwd-err":""}`}>
                <input
                  type={showPassword?.confirm_password?"text":"password"}
                  className={`form-control form-control-lg ml-0`}
                  placeholder="Confirm Password"
                  name="confirm_password"
                  onChange={InputEvent}
                />
                <div className="text-danger">{errors.confirm_password_err}</div>
                <img className="eye-icon" src={`http://54.151.95.130/assets/img/${showPassword?.confirm_password?"eye-slash.svg":"eye.svg"}`} style={{cursor:"pointer"}} alt="eye-icon" onClick={() => setShowPassword({...showPassword, confirm_password:!showPassword?.confirm_password})}/>
              </div>

              <div className="login-buttons">
                <button
                  type="submit"
                  class="btn btn-success btn-block btn-lg"
                  disabled={disable}
                >
                  {disable ? "Processing..." : "Reset Password"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default ResetPassword;