import React from 'react';

export default function Spinner() {
  return (
    <div className="text-center loadingScreen">
      <div class="spinner-border text-success" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  );
}
