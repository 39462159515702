import React, { useEffect, useState } from "react";
import { useLocation, useHistory, NavLink } from "react-router-dom";
import Loader from "../include/Loader";
import Menu from "../include/Menu";
import Footer from "../include/Footer";
import axios from "axios";
import { toast } from "react-toastify";

export default function RestoModifier() {
  const [modifierList, setModifierList] = useState([]);
  const location = useLocation();
  const [disable, setDisable] = useState(false);
  let history = useHistory();

  var data = location.state;
  const [errors, setErrors] = useState({});
  const [info, setInfo] = useState({
    name: data.item_name,
    description: data.item_desc,
    status: data.status,
    category_type: data.category_type,
    price: data.price,
    product_type: data.product_type,
  });

  useEffect(() => {
    document.getElementById("page-loader").style.display = "none";

    var element = document.getElementById("page-container");
    element.classList.add("show");
    getMenu();
  }, []);
  const getMenu = () => {
    const myurl = `${process.env.REACT_APP_URL}api/admin/restaurants-items-modifiers`;
    var bodyFormData = new URLSearchParams();
    bodyFormData.append("auth_code", "Brud#Cust$&$Resto#MD");
    bodyFormData.append("item_id", data.id);
    axios({
      method: "post",
      url: myurl,
      data: bodyFormData,
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    })
      .then((response) => {
        console.log(response);
        setModifierList(response["data"]["data"]);
      })
      .catch((error) => {
        console.log("Errors", error);
      });
  };
  const validate = () => {
    let input = info;
    let error = {};
    let isValid = true;

    if (!input["name"] || input["name"] == "") {
      isValid = false;
      error["name"] = "Please enter a item name.";
    }
    if (!input["description"] || input["description"] == "") {
      isValid = false;
      error["description"] = "Please enter description.";
    }
    console.log(input["status"]);
    if (input["status"] == null) {
      isValid = false;
      error["status"] = "Please enter a status.";
    }

    setErrors(error);
    return isValid;
  };
  const updateHandler = (e) => {
    e.preventDefault();
    setDisable(true);
    if (validate()) {
      const myurl = `${process.env.REACT_APP_URL}api/admin/restaurants-item-update`;
      var bodyFormData = new URLSearchParams();
      bodyFormData.append("auth_code", "Brud#Cust$&$Resto#MD");
      bodyFormData.append("item_id", data.id);
      bodyFormData.append("item_name", info.name);
      bodyFormData.append("item_desc", info.description);
      bodyFormData.append("status", info.status);

      axios({
        method: "post",
        url: myurl,
        data: bodyFormData,
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      })
        .then((response) => {
          console.log(response["data"]["data"]);
          setDisable(false);
          toast.success("Updated Successfully...!");
          // history.push(`/restaurantItems`);
        })
        .catch((error) => {
          console.log("Errors", error);
          toast.error("Something went wrong...!");

          setDisable(false);
        });
    } else {
      setDisable(false);
    }
  };
  return (
    <>
      <Loader />

      <div
        id="page-container"
        className="fade page-sidebar-fixed page-header-fixed"
      >
        <Menu />

        <div id="content" className="content">
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item">
              {/* <NavLink to="/restaurantItems"> */}
              <span className="basePath" onClick={useHistory().goBack}>
                Restaurant Items
              </span>
              {/* </NavLink> */}
            </li>
            <li className="breadcrumb-item  currentPath">
              Restaurant Modifier
            </li>
          </ol>
          <div style={{ display: "flex" }}>
            <i
              className="fa fa-arrow-left edit"
              onClick={useHistory().goBack}
              style={{
                cursor: "pointer",
                fontSize: "20px",
                marginTop: "7px",
                marginRight: "10px",
              }}
            ></i>
            <h1 className="page-header">Restaurant Modifier</h1>
          </div>

          <div className="card mainBody">
            <div className="card-body">
              <div
                className="row RestName p-5"
                style={{ borderRadius: "20px" }}
              >
                <div className="mx-auto ">
                  <span style={{ fontSize: "18px", fontWeight: "700" }}>
                    Food Order Detail
                  </span>
                </div>
              </div>
              <br />
              <form className="updateResto mb-4" onSubmit={updateHandler}>
                <div className="form-group mb-4">
                  <label for="exampleInputEmail1">Item Name : </label>
                  <input
                    type="text"
                    className="form-control ml-0"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Enter Item name"
                    name="name"
                    value={info.name}
                    readOnly
                    onChange={(e) => setInfo({ ...info, name: e.target.value })}
                  />
                  <div className="text-danger">{errors.name}</div>
                </div>

                <div class="form-group mb-4">
                  <label for="exampleFormControlTextarea1">Description :</label>
                  <textarea
                    className="form-control ml-0"
                    id="exampleFormControlTextarea1"
                    rows="3"
                    name="description"
                    placeholder="Please Enter Description"
                    value={info.description}
                    readOnly
                    onChange={(e) =>
                      setInfo({ ...info, description: e.target.value })
                    }
                  ></textarea>
                  <div className="text-danger">{errors.description}</div>
                </div>
                <div class="form-group mb-4">
                  <label for="exampleFormControlTextarea1">Status : </label>
                  <input
                    type="text"
                    className="form-control ml-0"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Enter Item name"
                    name="name"
                    value={info.status === 0 ? "Hide":"Unhide"}
                    readOnly
                  />
                  {/* <br />
                  <input
                    type="radio"
                    value="Hide"
                    checked={info.status == 0}
                    name="status"
                    onChange={() => setInfo({ ...info, status: 0 })}
                  />{" "}
                  Hide
                  <input
                    type="radio"
                    value="Unhide"
                    checked={info.status == 1}
                    name="status"
                    onChange={() => setInfo({ ...info, status: 1 })}
                  />{" "}
                  Unhide */}
                  <div className="text-danger">{errors.status}</div>
                </div>
                <div className="form-group mb-4">
                  <label for="exampleInputEmail1">Category :</label>
                  <input
                    type="text"
                    className="form-control ml-0"
                    value={info.category_type}
                    disabled={true}
                  />
                </div>
                <div className="form-group mb-4">
                  <label for="exampleInputEmail1">Price :</label>
                  <input
                    type="text"
                    className="form-control ml-0"
                    value={info.price}
                    disabled={true}
                  />
                </div>
                <div className="form-group mb-4">
                  <label for="exampleInputEmail1">Product Type :</label>
                  <input
                    type="text"
                    className="form-control ml-0"
                    value={info.product_type}
                    disabled={true}
                  />
                </div>
                <div className="form-group mb-4">
                  <label for="exampleInputEmail1">Image :</label>
                  <br />
                  <img
                    src={data.image}
                    className="form-img__img-preview"
                    style={{
                      width: "100px",
                      borderRadius: "20px",
                      margin: "5px",
                    }}
                    alt="imgs"
                  />
                </div>
                {/* <button
                  type="submit"
                  className="btn "
                  disabled={disable}
                  style={{
                    borderRadius: "20px",
                    backgroundColor: "#f55800",
                    color: "#fff",
                  }}
                >
                  {disable ? "Processing..." : "Update"}
                </button> */}
              </form>
              <hr />
              <h4>Modifiers : </h4>

              <div className="p-5 d-flex flex-wrap justify-content-around">
                {modifierList.length !== 0 ? (
                  modifierList?.map((e, i) => {
                    return (
                      <>
                        <div
                          className="card editBtn"
                          style={{
                            width: "18rem",
                            borderRadius: "20px",
                            margin: "10px",
                          }}
                        >
                          <div className="card-body ">
                            <span className="font-weight-bold ">
                              {e.group_name}
                              <div className="pull-right">
                                <span className="badge badge-danger">
                                  Price
                                </span>
                              </div>
                            </span>
                            <br />
                            <div className="mt-2">
                              {e.modifiers.map((e, i) => {
                                return (
                                  <>
                                    <span>
                                      {e.modifier_name}
                                      <div className="pull-right mr-1">
                                        {e.price}
                                      </div>
                                    </span>
                                    <br />
                                  </>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })
                ) : (
                  <>
                    <div className="row mx-auto">
                      <img
                        src={"/assets/img/icon/cart-icon.png"}
                        alt="RestoImage"
                        style={{
                          width: "50px",
                          height: "50px",
                          margin: "10px",
                        }}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
