import React, { useState, useEffect } from "react";
import { NavLink, Link, useHistory } from "react-router-dom";

import { toast } from "react-toastify";
import axios from "axios";

function Login() {
  const history = useHistory();
  const [loginInfo, setLoginInfo] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({});
  const [disable, setDisable] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const InputEvent = (e) => {
    const newLoginInfo = { ...loginInfo };
    newLoginInfo[e.target.name] = e.target.value;
    setLoginInfo(newLoginInfo);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (validate()) {
      setDisable(true);
      const { email, password } = loginInfo;

      const myurl = `${process.env.REACT_APP_URL}api/admin/login`;
      var bodyFormData = new URLSearchParams();
      bodyFormData.append("email", email);
      bodyFormData.append("password", password);
      axios({
        method: "post",
        url: myurl,
        data: bodyFormData,
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      })
        .then((response) => {
          //console.log(response);
          if (response.data.sucecess == true) {
            localStorage.setItem("BRUD_Admin_ID", response?.data?.data?.id);
            localStorage.setItem(
              "BRUD_Admin_EMAIL",
              response?.data?.data?.email
            );
            localStorage.setItem("BRUD_Admin_NAME", response?.data?.data?.name);
            localStorage.setItem(
              "BRUD_Admin_IMAGE",
              `${process.env.REACT_APP_URL}uploads/${response?.data?.data?.image}`
            );
            toast.success("Login Successfully...!");
            history.push(`/dashboard`);
          } else {
            setDisable(false);
            toast.error("Your Email Id and Password does not match...!");
          }
        })
        .catch((error) => {
          console.log("Errors", error);
          setDisable(false);
          toast.error("Your Email Id and Password does not match...!");
        });
    } else {
      setDisable(false);
    }
  };

  const validate = () => {
    let input = loginInfo;

    let errors = {};
    let isValid = true;

    if (!input["email"]) {
      isValid = false;
      errors["email_err"] = "Please enter email";
    }
    if (!input["password"]) {
      isValid = false;
      errors["password_err"] = "Please enter password";
    }

    setErrors(errors);
    return isValid;
  };

  useEffect(() => {
    document.getElementById("page-loader").style.display = "none";

    var element = document.getElementById("page-container");
    element.classList.add("show");
    if (localStorage.getItem("BRUD_Admin_ID")) {
      history.push('/dashboard')
    }
  }, []);

  return (
    <>
      <div id="page-loader" className="fade show">
        <span className="spinner"></span>
      </div>

      <div className="login-cover">
        <div
          className="login-cover-image"
          style={{
            backgroundImage: "url(assets/img/login-bg/login-bg-17.jpg)",
          }}
          data-id="login-cover-image"
        ></div>
        <div className="login-cover-bg"></div>
      </div>

      <div id="page-container" className="fade">
        <div
          className="login login-v2"
          data-pageload-addclassName="animated fadeIn"
        >
          <div className="login-header">
            <div className="brand">
              <span className="logo"></span> <b>BRUD</b> Admin
              <small>Login for BRUD admin panel</small>
            </div>
            <div className="icon">
              <i className="fa fa-lock"></i>
            </div>
          </div>

          <div className="login-content">
            <form onSubmit={(e) => submitHandler(e)}>
              <div className="form-group m-b-20">
                <input
                  type="text"
                  className="form-control form-control-lg ml-0"
                  placeholder="Email Address"
                  name="email"
                  onChange={InputEvent}
                />
                <div className="text-danger">{errors.email_err}</div>
              </div>
              <div className={`form-group m-b-20 password  ${errors.password_err ? "pwd-err" : ""}`}>
                <input
                  type={showPassword ? "text" : "password"}
                  className={`form-control form-control-lg ml-0`}
                  placeholder="Password"
                  name="password"
                  onChange={InputEvent}
                />
                <div className="text-danger">{errors.password_err}</div>
                <img className="eye-icon" src={`assets/img/${showPassword ? "eye-slash.svg" : "eye.svg"}`} style={{ cursor: "pointer" }} alt="eye-icon" onClick={() => setShowPassword(!showPassword)} />
              </div>

              <div className="form-group m-b-20 forgot-password" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <a href="/forgot-password" style={{ textDecoration: "underline" }}>Forgot Password?</a>
              </div>

              <div className="login-buttons">
                <button
                  type="submit"
                  class="btn btn-success btn-block btn-lg"
                  disabled={disable}
                >
                  {disable ? "Processing..." : "Sign in"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
